<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="$wait.is('users')"
      loading-text="Загрузка... Пожалуйста подождите"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      @current-items="currentItemsInfo = $event"
      :search="search"
      item-key="Id"
      no-data-text="Ничего не найдено"
      no-results-text="Ничего не найдено"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="8" sm="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Фильтр"
              clearable
            />
          </v-col>
          <v-col cols="4" sm="2" xl="1">
            <v-select
              :items="[
                {
                  text: '20',
                  value: 20,
                },
                {
                  text: '50',
                  value: 50,
                },
                {
                  text: 'Все',
                  value: -1,
                },
              ]"
              v-model="itemsPerPage"
              label="На странице"
            />
          </v-col>
          <v-col cols="12" sm="2" class="ml-auto d-flex justify-center align-center">
            <v-dialog v-model="dialog" max-width="650px" eager>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" fab dark small color="primary" v-on="on" @click="openDialog">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <span class="headline">Новый сотрудник</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="addUser" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                          <v-text-field
                            label="*Фамилия"
                            required
                            :rules="surnameRules"
                            v-model="newUser.Surname"
                            ref="newUserSurname"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                          <v-text-field
                            label="*Имя"
                            required
                            :rules="nameRules"
                            v-model="newUser.Name"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-0 pb-0">
                          <v-text-field
                            label="*Отчество"
                            required
                            :rules="fathersnameRules"
                            v-model="newUser.Fathersname"
                          />
                        </v-col>
                        <utils-tels-many
                          label="Телефон"
                          maskLabel="Формат"
                          v-model="newUser.Tels"
                          :canSelectType="true"
                        />
                        <v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            label="*Email"
                            required
                            :rules="emailRules"
                            v-model="newUser.Email"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-0">
                          <v-text-field
                            label="*Пароль"
                            required
                            :rules="passwordRules"
                            v-model="newUser.Password"
                            counter
                            :type="showPassword ? 'text' : 'password'"
                          >
                            <template v-slot:append>
                              <v-icon
                                tabindex="-1"
                                @click="showPassword = !showPassword"
                                v-if="showPassword"
                                >mdi-eye</v-icon
                              >
                              <v-icon
                                @click="showPassword = !showPassword"
                                tabindex="-1"
                                v-if="!showPassword"
                                >mdi-eye-off</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-0">
                          <v-text-field
                            label="*Повторите пароль"
                            required
                            :rules="passwordRepeatRules"
                            v-model="newUser.PasswordRepeat"
                            counter
                            :type="showPasswordRepeat ? 'text' : 'password'"
                          >
                            <template v-slot:append>
                              <v-icon
                                tabindex="-1"
                                @click="showPasswordRepeat = !showPasswordRepeat"
                                v-if="showPasswordRepeat"
                                >mdi-eye</v-icon
                              >
                              <v-icon
                                @click="showPasswordRepeat = !showPasswordRepeat"
                                tabindex="-1"
                                v-if="!showPasswordRepeat"
                                >mdi-eye-off</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          class="pt-0 pb-0 text-center"
                          v-if="passwordGenerated"
                        >
                          <span
                            >Сгенерированный пароль:
                            <span class="blue--text"
                              ><b>{{ passwordGenerated }}</b></span
                            ></span
                          >
                        </v-col>
                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                          <v-btn text block @click="generatePassword">
                            Сгенерировать пароль
                            <v-icon class="ml-5">mdi-lock-reset</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                          <v-checkbox v-model="newUser.Admin" label="Администратор"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                          <v-checkbox
                            v-model="newUser.notifyByEmail"
                            label="Отправить оповещение на Email"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <small>*обязательно к заполнению</small>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="dialog = false">Закрыть</v-btn>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="saveNewUser" :disabled="!valid">
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider />
      </template>
      <template v-slot:item.SNF="{ item }">
        <span class="link" @click="itemClicked(item.Id, $event)">
          {{ item.Surname }} {{ item.Name }} {{ item.Fathersname }}
        </span>
      </template>
      <template v-slot:item.Admin="{ item }">
        <v-chip color="green" text-color="white" v-if="item.Admin">Администратор</v-chip>
        <span v-else>Пользователь</span>
      </template>
      <template v-slot:item.Active="{ item }">
        <v-chip color="green" text-color="white" v-if="item.Active"> Активен </v-chip>
        <v-chip color="red" text-color="white" v-else> Заблокирован </v-chip>
      </template>
      <template v-slot:item.Tels="{ item }">
        <span v-for="n in item.Tels" :key="n.Id"
          >{{ n.Tel }}<span v-if="n.Type === 1"> - мобильный</span
          ><span v-if="n.Type === 2"> - офисный</span><br
        /></span>
      </template>
    </v-data-table>
    <div class="pt-12" v-if="items.length > 0">
      <div v-if="pageCount > 1" class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" />
      </div>
      <div class="text-center pt-2">
        <span v-if="items.length > currentItemsInfo.length">
          На странице: {{ currentItemsInfo.length }}
        </span>
        <br />
        <span>Всего: {{ items.length }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tableType: String,
    companyId: Number,
  },
  data() {
    return {
      newUser: {
        Name: '',
        Surname: '',
        Fathersname: '',
        Tels: [{ Id: '', Tel: '', Person_id: '', Format: 1, Type: 1 }],
        Email: '',
        Password: '',
        PasswordRepeat: '',
        NewPassword: '',
        Admin: false,
        notifyByEmail: false,
      },
      headers: [
        { value: 'SNF', text: 'ФИО', sortable: true, filterable: true, class: 'black_text' },
        { value: 'Email', text: 'Электронная почта', sortable: true, class: 'black_text' },
        { value: 'Tels', text: 'Телефон', sortable: true, class: 'black_text' },
        { value: 'Admin', text: 'Права', sortable: true, class: 'black_text' },
        { value: 'Active', text: 'Активен', sortable: true, class: 'black_text' },
        { value: 'Name', sortable: false, align: ' d-none' },
        { value: 'Surname', sortable: false, align: ' d-none' },
        { value: 'Fathersname', sortable: false, align: ' d-none' },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      currentItemsInfo: [],
      search: '',
      dialog: false,
      valid: true,
      showPassword: false,
      showPasswordRepeat: false,
      passwordGenerated: false,
      nameRules: [(v) => !!v || 'Имя обязательное поле!'],
      surnameRules: [(v) => !!v || 'Фамилия обязательное поле!'],
      fathersnameRules: [(v) => !!v || 'Отчество обязательное поле!'],
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      emailRules: [
        (v) => {
          if (!v) return true;
          return this.emailRegex.test(v) || 'Введите корректный E-mail';
        },
      ],
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      passwordRules: [
        (v) => {
          if (!v) return false;
          return (
            this.passwordRegex.test(v) ||
            'Минимум 8 символов, 1 большая буква, 1 маленькая буква, 1 цифра'
          );
        },
      ],
      passwordRepeatRules: [
        (v) => {
          return v === this.newUser.Password || 'Пароли не совпадают';
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.getters['users/USERS'];
    },
  },
  async beforeDestroy() {
    await this.$store.dispatch('users/EMPTY_USERS'); // что бы не светились 0.1 секунду старые данные при переключении
  },
  async created() {
    this.$wait.start('users');
    if (localStorage.Users_itemsPerPage) {
      this.itemsPerPage = parseInt(localStorage.Users_itemsPerPage, 10);
    }
    await this.$store.dispatch('users/GET_USERS').catch(() => {}); // если нет хоть какой то обработки ошибки - функция дальше не выполняеться;
    this.$wait.end('users');
  },
  methods: {
    async refresh() {
      this.$wait.start('users');
      await this.$store.dispatch('users/GET_USERS').catch(() => {}); // если нет хоть какой то обработки ошибки - функция дальше не выполняеться
      this.$wait.end('users');
    },
    toLink(id) {
      return `/admin/users/${id.toString()}`;
    },
    itemClicked(id, event) {
      event.preventDefault();
      this.$router.push({ path: this.toLink(id) });
    },
    openDialog() {
      if (this.$refs.addUser) {
        this.newUser.Name = '';
        this.newUser.Surname = '';
        this.newUser.Fathersname = '';
        this.newUser.Tels = [];
        this.newUser.Email = '';
        this.newUser.Password = '';
        this.newUser.PasswordRepeat = '';
        this.newUser.NewPassword = '';
        this.newUser.Admin = false;
        this.newUser.notifyByEmail = false;
        this.showPassword = false;
        this.showPasswordRepeat = false;
        this.passwordGenerated = false;
        this.$refs.addUser.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.newUserSurname.focus();
          });
        });
      });
    },
    async saveNewUser() {
      if (this.$refs.addUser.validate()) {
        this.newUser.NewPassword = this.newUser.Password;
        await this.$store.dispatch('users/ADD_NEW_USER', this.newUser).then(
          () => {
            this.dialog = false;
            this.refresh();
            this.$toastr.s(
              'Сотрудник "' +
                this.newUser.Surname +
                ' ' +
                this.newUser.Name +
                ' ' +
                this.newUser.Fathersname +
                '" добавлен.'
            );
          },
          (error) => {
            if (error.includes('Error 1062: Duplicate entry')) {
              this.$toastr.e('Сотрудник с таким Email уже существует');
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    generatePassword() {
      let once = false;
      let newPassword = Math.random()
        .toString(36)
        .substring(2, 10)
        .split('')
        .map((char) => {
          if (!Number(char) && !once && char !== '0') {
            once = true;
            return char.toUpperCase();
          }
          return char;
        })
        .join('');

      once = false;
      if (!/\d/.test(newPassword)) {
        newPassword = newPassword
          .substring(0, 10)
          .split('')
          .map((char) => {
            if (char !== char.toUpperCase() && !once) {
              once = true;
              return Math.floor(Math.random() * Math.floor(10)).toString();
            }
            return char;
          })
          .join('');
      }

      this.newUser.Password = newPassword;
      this.newUser.PasswordRepeat = newPassword;
      this.passwordGenerated = newPassword;
    },
  },
  watch: {
    itemsPerPage(itemsPerPage) {
      localStorage.Users_itemsPerPage = itemsPerPage;
    },
  },
};
</script>

<style lang="css" scoped>
.link {
  color: #1976d2;
  cursor: pointer;
  background-color: transparent;
}
.invisible {
  display: none;
}
.v-data-table >>> .black_text {
  color: black !important;
}
.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
