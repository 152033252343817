<template>
  <div id="telWithButton" class="telWithButton">
    <div class="label">
      <span
        ><b>{{ label }}:</b></span
      >
      <span class="mdi mdi-square-edit-outline input-toggler" @click="inputBoxShow" />
    </div>
    <div style="height: 54px">
      <div v-if="!loader">
        <span v-if="getValue !== '' && getValue !== null">
          {{ getValue }}
        </span>
        <span v-else>[Не задано]</span>
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
    <div v-if="inputShow">
      <div class="changeable_holder" v-if="inputShow" id="changeable_holder_tel">
        <div class="select_tel" id="select_tel">
          <v-form ref="telWithButton" v-model="valid" lazy-validation v-on:submit.prevent="">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <div id="select_tel_select">
                  <v-select
                    v-model="mask"
                    attach="#select_tel_select .v-input"
                    :items="[
                      {
                        text: '(###) ###-##-##',
                        value: '(###) ###-##-##',
                      },
                      {
                        text: '(###-##) #-##-##',
                        value: '(###-##) #-##-##',
                      },
                      {
                        text: 'Без маски',
                        value: '################',
                      },
                    ]"
                    :label="maskLabel"
                  />
                </div>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  :label="label"
                  v-model="getValue"
                  v-mask="mask"
                  :rules="validationRulesList"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-btn color="blue darken-1" class="float-left" text @click="inputBoxHide">
                  Отменить
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  class="float-right"
                  text
                  @click="call_Callback"
                  :disabled="!valid"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },
  name: 'telWithButton',
  data() {
    return {
      valid: true,
      localValue: '',
      inputShow: false,
      escapeValue: '',
      mask: '(###) ###-##-##',
    };
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    maskLabel: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    openedInputVar: {
      type: Boolean,
      required: true,
    },
    loader: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    validationRulesList() {
      if (this.required) {
        return [
          (v) => !!v || 'Это обязательное поле!',
          (v) => {
            let maxLength;
            switch (this.mask) {
              case '(###) ###-##-##':
                maxLength = 15;
                break;
              case '(###-##) #-##-##':
                maxLength = 16;
                break;
              case '################':
                maxLength = 1;
                break;
            }
            return (v && v.length >= maxLength) || 'Введите номер полностью';
          },
        ];
      } else {
        return [
          (v) => {
            let maxLength;
            switch (this.mask) {
              case '(###) ###-##-##':
                maxLength = 15;
                break;
              case '(###-##) #-##-##':
                maxLength = 16;
                break;
              case '################':
                maxLength = 1;
                break;
            }
            if (!v) return true;
            return (v && v.length >= maxLength) || 'Введите номер полностью';
          },
        ];
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
  },
  methods: {
    inputBoxShow() {
      if (!this.openedInputVar) {
        this.escapeValue = this.value;
        this.inputShow = true;
        this.localValue = this.value;
        document.addEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      } else {
        this.$toastr.e('Есть открытые и не заполненнные элементы');
      }
    },
    inputBoxHide() {
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },
    inputBoxHideByOuterClick(e) {
      let _root = document.getElementById('changeable_holder_tel');
      let isContain = _root.contains(e.target);
      if (isContain) {
        return;
      }
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },

    call_Callback() {
      if (!this.$refs.telWithButton.validate()) {
        return;
      }
      this.inputBoxHide();
      if (this.localValue === this.value) {
        return; // Если значение не поменялось - ничего не делаем
      }
      // this.$emit('input', this.localValue);
      this.$emit('callback', this.localValue);
    },
    escape() {
      this.localValue = this.escapeValue;
      this.inputShow = false;
    },
  },
};
</script>

<style scoped>
.telWithButton {
  position: relative;
}
.changeable_holder {
  position: absolute;
  left: -17px;
  top: 25px;
  min-width: 300px;
  z-index: 10;
  background: #fff;
  margin: 0px 15px;
}

.select_tel {
  box-sizing: border-box;
  margin: 0;
  padding: 15px;
  border: 1px solid #dadfe6;
}

.input-toggler {
  cursor: pointer;
  padding-left: 8px;
}

.label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
</style>
