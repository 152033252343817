import Vue from 'vue';
import Vuex from 'vuex';

// main store
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

// auth
import auth from '@/utils/auth/store/';
// app
import app from '@/utils/app/store/';

import users from '@/routes/app/routes/users/store';
import profile from '@/routes/app/routes/profile/store';
import email_settings from '@/routes/app/routes/settings/email/email_store';

import componentModules from '@/components';
const customModules = {
  users,
  profile,
  email_settings,
};

const mainModules = { auth, app };
const modules = Object.assign(componentModules, mainModules, customModules);

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
});
