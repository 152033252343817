<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-row no-gutters>
          <v-col cols="12" class="pt-0 pb-0">
            <v-card class="mr-1 mb-1">
              <v-overlay absolute :value="$wait.is('person')" opacity="1" color="white">
                <v-progress-circular indeterminate size="64" color="primary" />
              </v-overlay>
              <v-container fluid v-bind:class="{ invisible: $wait.is('email_settings') }">
                <v-row>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <utils-input-with-button
                      :opened-input-var="openedInput"
                      v-model="emailSettingsVuex.emailAddress"
                      label="Email Адрес"
                      isEmail
                      :loader="$wait.is('personChangeData-emailAddress')"
                      @callback="changeEmailSettingsData($event, 'emailAddress')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <utils-input-with-button
                      :opened-input-var="openedInput"
                      v-model="emailSettingsVuex.emailPassword"
                      label="Email пароль"
                      :loader="$wait.is('personChangeData-emailPassword')"
                      @callback="changeEmailSettingsData($event, 'emailPassword')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <utils-input-with-button
                      :opened-input-var="openedInput"
                      v-model="emailSettingsVuex.smtpPort"
                      label="smtpPort"
                      :loader="$wait.is('personChangeData-smtpPort')"
                      @callback="changeEmailSettingsData($event, 'smtpPort')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <utils-input-with-button
                      :opened-input-var="openedInput"
                      v-model="emailSettingsVuex.smtpServer"
                      label="smtpServer"
                      :loader="$wait.is('personChangeData-smtpServer')"
                      @callback="changeEmailSettingsData($event, 'smtpServer')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      openedInput: false,
    };
  },
  computed: {
    emailSettingsVuex() {
      if (this.$store.getters['email_settings/EMAIL_SETTINGS']) {
        let settingsArray = this.$store.getters['email_settings/EMAIL_SETTINGS'];
        return settingsArray.reduce((acc, { PropertyName, PropertyValue }) => {
          return Object.defineProperty(acc, PropertyName, { value: PropertyValue });
        }, {});
      }
      return {};
    },
  },
  methods: {
    async changeEmailSettingsData(value, field) {
      this.$wait.start('emailSettingsChangeData-' + field);
      await this.$store
        .dispatch('email_settings/CHANGE_EMAIL_DATA', {
          value,
          field,
        })
        .then(
          () => {
            this.$toastr.s('Изменено успешно');
          },
          (error) => {
            this.$toastr.e(error, 'Произошла ошибка');
          }
        );
      await this.$store.dispatch('email_settings/GET_EMAIL_SETTINGS');
      this.$wait.end('emailSettingsChangeData-' + field);
    },
    async setBreadcrumbs() {
      await this.$store.dispatch('app/SET_BREADCRUMBS', [
        { text: 'Главная', to: '/admin', disabled: false },
        { text: 'Настройки', to: '/admin/settings', disabled: false },
        { text: 'Email', disabled: true },
      ]);
    },
  },
  async created() {
    this.setBreadcrumbs();
    this.$wait.start('email_settings');
    await this.$store.dispatch('email_settings/GET_EMAIL_SETTINGS').then(
      () => {
        this.$wait.end('email_settings');
      },
      (error) => {
        this.$toastr.e(error, 'Неизвестная ошибка');
        this.$router.push({ path: this.$store.state.app.backURL });
      }
    );
  },
};
</script>
<style lang="css">
.invisible {
  opacity: 0;
}
</style>
