<template>
  <div id="telsManyWithButton" class="telsManyWithButton">
    <div class="label">
      <span
        ><b>{{ label }}:</b></span
      >
      <span class="mdi mdi-square-edit-outline input-toggler" @click="inputBoxShow" />
    </div>
    <div style="">
      <div v-if="!loader">
        <div v-if="getValue !== null && getValue !== undefined">
          <div v-if="value.length !== 0">
            <span>
              <div v-for="(item, index) in getValue" :key="index" class="mb-2">
                {{ item.Tel }} <span v-if="item.Type === 1"> - мобильный</span
                ><span v-if="item.Type === 2"> - офисный</span>
              </div>
            </span>
          </div>
          <div v-else>
            <span>[Не задано]</span>
          </div>
        </div>
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
    <div v-if="inputShow">
      <div class="changeable_holder" v-if="inputShow" id="changeable_holder_tels">
        <div class="select_tel" id="select_tel">
          <v-form ref="telsManyWithButton" v-model="valid" lazy-validation v-on:submit.prevent="">
            <v-row>
              <component
                v-for="(component, index) in components"
                :key="index"
                :is="component"
                :label="label"
                :maskLabel="maskLabel"
                v-model="localValueArray[index].Tel"
                :allowDelete="allowDelete"
                :index="index"
                :format="localValueArray[index].Format"
                :NumberType="localValueArray[index].Type"
                @deleteSelf="handleDestroy"
                @changeFormat="handleChangeFormat($event, index)"
                @changeNumberType="handleChangeNumberType($event, index)"
              />

              <v-col cols="12" sm="12" class="pt-0 pb-0">
                <v-btn text block @click="add">ДОБАВИТЬ НОМЕР</v-btn>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-btn color="blue darken-1" class="float-left" text @click="inputBoxHide">
                  Отменить
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  class="float-right"
                  text
                  @click="call_Callback"
                  :disabled="!valid"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tel from '../tel/Index';
import _ from 'lodash';

export default {
  name: 'telsManyWithButton',
  data() {
    return {
      components: [tel],
      valid: true,
      localValueArray: [{ Id: '', Tel: '', Format: 1, Type: undefined }],
      inputShow: false,
      escapeValue: [],
      reset: false,
    };
  },
  props: {
    value: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    maskLabel: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    openedInputVar: {
      type: Boolean,
      required: true,
    },
    loader: {
      type: Boolean,
      required: false,
    },
    canSelectType: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValueArray = value;
      },
    },
    allowDelete: function () {
      return this.components.length > 1;
    },
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
  },
  methods: {
    handleChangeFormat(format, index) {
      this.localValueArray[index].Format = format;
    },
    handleChangeNumberType(type, index) {
      this.localValueArray[index].Type = type;
    },
    handleDestroy(index) {
      this.localValueArray.splice(index, 1);
      this.components.splice(index, 1);
    },
    add() {
      if (this.canSelectType) {
        this.localValueArray.push({ Id: '', Tel: '', Format: 1, Type: 1 });
      } else {
        this.localValueArray.push({ Id: '', Tel: '', Format: 1, Type: undefined });
      }
      this.components.push(tel);
    },
    inputBoxShow() {
      if (!this.openedInputVar) {
        this.escapeValue = this.value;
        this.localValueArray = _.cloneDeep(this.value);
        this.components = [tel];
        if (Array.isArray(this.localValueArray) && this.localValueArray.length) {
          for (let i = 0; i < this.localValueArray.length - 1; i++) {
            this.components.push(tel);
          }
        } else {
          if (this.canSelectType) {
            this.localValueArray = [{ Id: '', Tel: '', Format: 1, Type: 1 }];
          } else {
            this.localValueArray = [{ Id: '', Tel: '', Format: 1, Type: undefined }];
          }
        }
        this.inputShow = true;
        document.addEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      } else {
        this.$toastr.e('Есть открытые и не заполненнные элементы');
      }
    },
    inputBoxHide() {
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },
    inputBoxHideByOuterClick(e) {
      let _root = document.getElementById('changeable_holder_tels');
      let isContain = _root.contains(e.target);
      if (isContain) {
        return;
      }
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },

    call_Callback() {
      if (!this.$refs.telsManyWithButton.validate()) {
        return;
      }
      this.inputBoxHide();
      if (this.localValueArray === this.value) {
        return; // Если значение не поменялось - ничего не делаем
      }
      // this.$emit('input', this.localValue);
      this.$emit('callback', this.localValueArray);
    },
    escape() {
      this.localValueArray = this.escapeValue;
      this.inputShow = false;
    },
  },
};
</script>

<style scoped>
.telsManyWithButton {
  position: relative;
}
.changeable_holder {
  position: absolute;
  left: -17px;
  top: 25px;
  min-width: 600px;
  z-index: 10;
  background: #fff;
  margin: 0px 15px;
}

.select_tel {
  box-sizing: border-box;
  margin: 0;
  padding: 15px;
  border: 1px solid #dadfe6;
}

.input-toggler {
  cursor: pointer;
  padding-left: 8px;
}

.label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
</style>
