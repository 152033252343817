<template>
  <v-row class="pr-0 pl-0">
    <component
      v-for="(component, index) in components"
      :key="index"
      :is="component"
      :label="label"
      :maskLabel="maskLabel"
      :reset="reset"
      v-model="localValueArray[index].Tel"
      :allowDelete="allowDelete"
      :index="index"
      :format="localValueArray[index].Format"
      :NumberType="localValueArray[index].Type"
      @input="handleInput"
      @deleteSelf="handleDestroy"
      @changeNumberType="handleChangeNumberType($event, index)"
    />

    <v-col cols="12" sm="12" class="pt-0 pb-0">
      <v-btn text block @click="add">ДОБАВИТЬ НОМЕР</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import tel from '../tel/Index';
export default {
  name: 'telsMany',
  data() {
    return {
      components: [tel],
      localValueArray: [{ Id: '', Tel: '', Format: 1, Type: undefined }],
      reset: false,
    };
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maskLabel: {
      type: String,
      required: true,
    },
    canSelectType: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValueArray = value;
      },
    },
    allowDelete: function () {
      return this.components.length > 1;
    },
  },
  methods: {
    handleChangeNumberType(type, index) {
      this.localValueArray[index].Type = type;
    },
    handleInput() {
      this.$emit('input', this.localValueArray);
    },
    handleDestroy(index) {
      this.localValueArray.splice(index, 1);
      this.components.splice(index, 1);
    },
    add() {
      if (this.canSelectType) {
        this.localValueArray.push({ Id: '', Tel: '', Format: 1, Type: 1 });
      } else {
        this.localValueArray.push({ Id: '', Tel: '', Format: 1, Type: undefined });
      }
      this.components.push(tel);
    },
  },
  watch: {
    value: function (val) {
      if (val.length === 0) {
        this.reset = true;
        this.$nextTick(() => {
          this.localValueArray = [{ Id: '', Tel: '', Format: 1, Type: undefined }];
          if (this.canSelectType) {
            this.localValueArray = [{ Id: '', Tel: '', Format: 1, Type: 1 }];
          }
          this.components = [tel];
          this.reset = false;
        });
      }
    },
  },
};
</script>

<style scoped></style>
