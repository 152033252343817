<template>
  <div class="login-form white">
    <v-alert class="login-form__fail-alert px-5" type="error" :value="loginFailed" dismissible>
      Попытка входа не удалась
    </v-alert>
    <v-alert class="login-form__fail-alert px-5" type="error" :value="networkError" dismissible>
      Нет связи с сервером
    </v-alert>
    <div class="login-form__wrapper d-flex flex-column align-center justify-center">
      <v-progress-circular
        v-if="loginWait"
        :size="100"
        :width="5"
        class="login-form__loader"
        color="primary"
        indeterminate
      />

      <template v-else>
        <!-- logo -->
        <img class="mb-5" :src="require(`@/assets/Einhell_logo.jpg`)" style="width: 450px" />

        <!-- app title -->
        <h1 class="login-form__title text-center display-1 font-weight-bold mb-10">Сервис</h1>

        <!-- login form -->
        <v-form v-model="valid" class="login-form__form" ref="form" lazy-validation @submit.prevent>
          <v-text-field
            label="E-mail"
            v-model="user"
            :rules="loginRules"
            required
            autofocus
            validate-on-blur
            @keyup.enter="loginAttempt"
          />
          <v-text-field
            label="Пароль"
            v-model="password"
            :rules="passwordRules"
            :counter="30"
            required
            :append-icon="passAppendIcon"
            @click:append="() => (passwordHidden = !passwordHidden)"
            :type="passTextFieldType"
            class="mb-5"
            @keyup.enter="loginAttempt"
          />
          <div class="justify-center" style="display: flex">
            <v-btn @click="loginAttempt()" :disabled="!valid" class="primary white--text">
              Войти
            </v-btn>
          </div>
        </v-form>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'login',
  props: {
    redirect: {
      type: String,
      default: '/',
    },
    logo: {
      type: String,
      default: 'vuetify-logo-dark.png',
    },
    logoStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valid: false,
      password: '',
      user: '',
      passwordHidden: true,
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      loginWait: false,
      loginFailed: false,
      networkError: false,
    };
  },
  computed: {
    loginRules() {
      return [
        (v) => !!v || 'E-mail не может быть пустым',
        (v) => this.emailRegex.test(v) || 'Введите корректный E-mail',
      ];
    },
    passwordRules() {
      return [(v) => !!v || 'Пароль не может быть пустым'];
    },
    credential() {
      const credentials = new FormData();
      credentials.append('Email', this.user);
      credentials.append('Password', this.password);
      return credentials;
    },
    passTextFieldType() {
      return this.passwordHidden ? 'password' : 'text';
    },
    passAppendIcon() {
      return this.passwordHidden ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  methods: {
    ...mapActions('auth', ['LOGIN']),
    loginAttempt() {
      if (this.$refs.form.validate()) {
        this.loginWait = true;
        this.networkError = false;
        this.loginFailed = false;

        this.LOGIN(this.credential).then(
          () => {
            this.$router.push({ path: this.$store.state.app.backURL });
          },
          (error) => {
            this.loginWait = false;
            if (error.message === 'Network Error') {
              this.networkError = true;
            } else {
              this.loginFailed = true;
            }
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  &__fail-alert {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__wrapper {
    height: 100vh;
    width: 100%;
  }
  &__form {
    width: 300px;
  }
  &__logo {
    width: 100%;
    height: auto;
  }
}
</style>
