<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-card>
          <v-tabs>
            <v-tab ripple @change="getRequests(startDate, endDate)"> Гарантийные талоны </v-tab>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <v-row>
                    <v-col cols="4" md="4" sm="12" class="d-flex align-center" style="padding: 0">
                      <date-picker
                        v-model="rangeDate"
                        range
                        format="DD/MM/YYYY"
                        :editable="false"
                        :clearable="false"
                        :shortcuts="shortcuts"
                        ref="datepicker"
                        @change="changeDate()"
                        :popup-style="{ position: 'fixed' }"
                        :lang="lang"
                      >
                        <template v-slot:icon-calendar>
                          <v-icon></v-icon>
                        </template>
                      </date-picker>
                      <div ref="target_datepicker"></div>
                    </v-col>
                    <v-col cols="6" md="6" sm="12" style="padding: 0">
                      <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        v-model="searchString"
                        label="Фильтр"
                        clearable
                        @input="search(searchString)"
                      />
                    </v-col>
                    <v-col cols="2" sm="2">
                      <v-btn
                        class="mx-2 float-right"
                        fab
                        dark
                        small
                        color="primary"
                        @click="downloadReport"
                      >
                        <v-icon dark>mdi-file-download-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-auto d-flex justify-center align-center">
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-data-table
                    :headers="headers"
                    :items="filteredArray"
                    :loading="$wait.is('requests')"
                    loading-text="Загрузка... Пожалуйста подождите"
                    hide-default-footer
                    :page.sync="page"
                    :items-per-page="-1"
                    @page-count="pageCount = $event"
                    @current-items="currentItemsInfo = $event"
                    item-key="Id"
                    no-data-text="Ничего не найдено"
                    no-results-text="Ничего не найдено"
                  >
                    <template v-slot:top> </template>
                    <template v-slot:item.Serials="{ item }">
                      <span v-for="el in item.WarrantySerials" :key="el.Id">
                        {{ el.Serial }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon class="mr-4" @click="downloadWarranty(item)">
                        mdi-file-download
                      </v-icon>

                      <v-icon
                        v-if="checkPermission('FULL-PERMISSIONS')"
                        @click.stop="
                          dialogDelete = true;
                          toDeleteWarrantyId = item.Id;
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                  <v-dialog v-model="dialogDelete" max-width="400px" eager>
                    <v-card>
                      <v-card-title class="d-flex justify-center">
                        <span class="headline">Удалить гарантийный талон?</span>
                      </v-card-title>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="
                            dialogDelete = false;
                            toDeleteWarrantyId = undefined;
                          "
                          >Отмена</v-btn
                        >
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteWarranty(toDeleteWarrantyId)"
                        >
                          Удалить
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <div class="pt-12" v-if="items.length > 0">
                    <div v-if="pageCount > 1" class="text-center pt-2">
                      <v-pagination v-model="page" :length="pageCount" />
                    </div>
                    <div class="text-center pt-2">
                      <span v-if="items.length > currentItemsInfo.length">
                        На странице: {{ currentItemsInfo.length }}
                      </span>
                      <br />
                      <span>Всего: {{ items.length }}</span>
                    </div>
                  </div>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      headers: [
        { value: 'Id', text: 'Id', sortable: false, filterable: true },
        { value: 'BuyDate', text: 'Дата', sortable: true, filterable: true },
        { value: 'Sku', text: 'Артикул', sortable: false, filterable: true },
        { value: 'Model', text: 'Модель', sortable: false, filterable: true },
        { value: 'Serials', text: 'Серийные номера', sortable: false, filterable: true },
        { value: 'Fio', text: 'ФИО', sortable: false, filterable: true },
        { value: 'Email', text: 'E-mail', sortable: false, filterable: true },
        { value: 'Tel', text: 'Телефон', sortable: false, filterable: true },
        { value: 'BuyPlace.Name', text: 'Где купили', sortable: false, filterable: true },
        { value: 'Seller', text: 'Продавец', sortable: false, filterable: true },
        { text: '', value: 'actions', sortable: false },
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'январь',
            'февраль',
            'март',
            'апрель',
            'май',
            'июнь',
            'июль',
            'август',
            'сентябрь',
            'октябрь',
            'ноябрь',
            'декабрь',
          ],
          monthsShort: [
            'январь',
            'февраль',
            'март',
            'апрель',
            'май',
            'июнь',
            'июль',
            'август',
            'сентябрь',
            'октябрь',
            'ноябрь',
            'декабрь',
          ],
          weekdays: [
            'воскресенье',
            'понедельник',
            'вторник',
            'среда',
            'четверг',
            'пятница',
            'суббота',
          ],
          weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          firstWeekContainsDate: 1,
        },
        monthBeforeYear: false,
      },
      currentItemId: 0,
      items: [],
      page: 1,
      pageCount: 0,
      currentItemsInfo: [],
      searchString: '',
      filteredArray: [],
      rangeDate: [new Date(), new Date()],
      shortcuts: [
        { text: 'Сегодня', onClick: () => [new Date(), new Date()] },
        {
          text: 'Вчера',
          onClick: () => [new Date(Date.now() - 86400000), new Date(Date.now() - 86400000)],
        },
        {
          text: 'Последние 7 дней',
          onClick: () => [new Date(Date.now() - 1000 * 60 * 60 * 24 * 7), new Date()],
        },
        {
          text: 'Последние 30 дней',
          onClick: () => [new Date(Date.now() - 1000 * 60 * 60 * 24 * 30), new Date()],
        },
        {
          text: 'Текущий месяц',
          onClick: () => {
            const date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            return [firstDay, date];
          },
        },
        {
          text: 'Прошлый месяц',
          onClick: () => {
            const date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            return [firstDay, lastDay];
          },
        },
      ],
      dialogDelete: false,
      toDeleteWarrantyId: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', ['checkPermission']),
    startDate() {
      return this.$moment(this.rangeDate[0]).format('DD/\MM/\YYYY');
    },
    endDate() {
      return this.$moment(this.rangeDate[1]).format('DD/\MM/\YYYY');
    },
    emailSettingsVuex() {
      if (this.$store.getters['email_settings/EMAIL_SETTINGS']) {
        let settingsArray = this.$store.getters['email_settings/EMAIL_SETTINGS'];
        return settingsArray.reduce((acc, { PropertyName, PropertyValue }) => {
          return Object.defineProperty(acc, PropertyName, { value: PropertyValue });
        }, {});
      }
      return {};
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            const el = this.$refs.datepicker.$refs.popup.$el;
            this.$refs.target_datepicker.appendChild(el);
          });
        });
      });
    });
  },
  async created() {
    const date = new Date();
    let firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.rangeDate[0] = firstMonthDay;
    this.rangeDate[1] = date;
    await this.$store.dispatch('email_settings/GET_EMAIL_SETTINGS').then(
      () => {},
      (error) => {
        this.$toastr.e(error, 'Неизвестная ошибка');
        this.$router.push({ path: this.$store.state.app.backURL });
      }
    );
    await this.getRequests(this.startDate, this.endDate);
  },
  methods: {
    downloadReport() {
      let outputArray = [];
      this.filteredArray.forEach((item) => {
        let serialsString = '';
        item.WarrantySerials.forEach((el) => {
          serialsString = serialsString + el.Serial + ' ';
        });
        serialsString = serialsString.slice(0, -1);
        outputArray.push({
          Id: item.Id,
          Артикул: item.Sku,
          Модель: item.Model,
          'Дата покупки': item.BuyDate,
          ФИО: item.Fio,
          Email: item.Email,
          Телефон: item.Tel,
          'Где купили:': item.BuyPlace.Name,
          'Серийные номера': serialsString,
          Продавец: item.Seller,
          'Ссылка на гар. талон': this.emailSettingsVuex.siteAddress + '/w/' + item.PdfId,
        });
      });
      let workbook = XLSX.utils.book_new();
      let sheet1 = XLSX.utils.sheet_add_json(workbook, outputArray);

      let range = XLSX.utils.decode_range(sheet1['!ref']);

      for (let i = 1; i < 11; i++) {
        for (let r = range.s.r; r <= range.e.r; r++) {
          let cellName = XLSX.utils.encode_cell({ c: i, r: r });
          sheet1[cellName].z = '@';
        }
      }

      XLSX.utils.book_append_sheet(workbook, sheet1, 'Лист1');
      XLSX.writeFile(workbook, 'report.xlsx');
    },
    async changeDate() {
      let start = this.$moment(this.rangeDate[0]).format('DD/\MM/\YYYY');
      let end = this.$moment(this.rangeDate[1]).format('DD/\MM/\YYYY');
      await this.getRequests(start, end);
      this.searchString = '';
    },
    downloadWarranty(item) {
      window.open(this.emailSettingsVuex.siteAddress + '/w/' + item.PdfId, '_blank');
    },
    async deleteWarranty(id) {
      if (id) {
        await HTTP.post('warranty_requests/delete/' + id).then(
          async () => {
            this.$toastr.s('Удалено успешно');
            this.dialogDelete = false;
            await this.getRequests(this.startDate, this.endDate);
          },
          (error) => {
            this.dialogDelete = false;
            if (error.response.data.Error) {
              this.$toastr.e(error.response.data.Error);
            } else {
              this.$toastr.e(error);
            }
          }
        );
        this.toDeleteWarrantyId = undefined;
      } else {
        this.$toastr.e('ID не задан!');
        this.dialogDelete = false;
      }
    },
    async getRequests(start, end) {
      this.$wait.start('requests');
      HTTP.get('warranty_requests?start=' + start + '&end=' + end).then(
        (response) => {
          this.$wait.end('requests');
          this.items = response.data;
          this.filteredArray = this.items;
        },
        (error) => {
          this.$wait.end('requests');
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    search(searchString) {
      if (!searchString) {
        this.filteredArray = this.items;
        return;
      }
      searchString = this.fullTrim(searchString);
      let localArray = _.cloneDeep(this.items);

      localArray = localArray.filter(
        (el) =>
          this.fullTrim(el.Id).includes(searchString) ||
          this.fullTrim(el.Sku).includes(searchString) ||
          this.fullTrim(el.Model).includes(searchString) ||
          this.fullTrim(el.BuyDate).includes(searchString) ||
          this.fullTrim(el.Fio).includes(searchString) ||
          this.fullTrim(el.Email).includes(searchString) ||
          this.fullTrim(el.Tel).includes(searchString) ||
          this.fullTrim(el.Seller).includes(searchString) ||
          el.WarrantySerials.some((element) => this.fullTrim(element.Serial).includes(searchString))
      );
      this.filteredArray = localArray;
    },
    fullTrim(string) {
      if (typeof string === 'string') {
        string = string.toLowerCase();
      } else {
        string = String(string);
        string = string.toLowerCase();
      }
      string = string.replaceAll(' ', '');
      string = string.replaceAll('(', '');
      string = string.replaceAll(')', '');
      string = string.replaceAll('-', '');
      return string;
    },
  },
};
</script>
<style lang="css" scoped>
.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

>>> .mx-datepicker-sidebar {
  width: 155px !important;
}
>>> .mx-datepicker-content {
  margin-left: 155px !important;
}
</style>
