<template>
  <v-footer id="core-footer" absolute height="30">
    <div class="footer-items"></div>
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ new Date().getFullYear() }}
      <a href="https://www.company-name.com/" target="_blank">Company Name</a>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
