import Vue from 'vue';
import Router from 'vue-router';
import App from './routes/app/Index';
import appRoutes from './routes/app/router';
import Login from './routes/login/Index';
import Request from './routes/request-page/Index';
// eslint-disable-next-line no-unused-vars
import store from '@/store/';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/admin',
      name: '',
      meta: {
        label: 'Главная',
      },
      component: App,
      children: appRoutes,
      beforeEnter: (to, from, next) => {
        let auth = localStorage.getItem('token');
        if (!auth) {
          store.commit('auth/logout');
          next('/login');
        } else {
          next();
        }
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'request',
      component: Request,
    },
  ],
});
//
router.beforeEach(function (to, from, next) {
  let middleware;
  to.matched.some((m) => {
    middleware = m.meta.guard;
  });
  if (typeof middleware === 'undefined') {
    next();
  } else {
    if (store.getters['auth/checkPermission'](middleware)) {
      window.scrollTo(0, 0);
      next();
    } else if (localStorage.getItem('logged')) {
      next('/');
    } else {
      next('/login');
    }
  }
  store.commit('app/setBreadcrumbs', null);
  if (from.path !== '/login') {
    if (from.path === '/') {
      store.commit('app/setBackURL', '/admin');
    } else {
      store.commit('app/setBackURL', from.path);
    }
  }
});

export default router;
