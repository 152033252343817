<template>
  <div>
    <v-app-bar id="core-toolbar" app style="background: #eee">
      <div class="v-toolbar-title">
        <v-toolbar-title class="tertiary--text font-weight-light">
          <v-btn v-if="responsive" class="default v-btn--simple" icon @click.stop="onClickBtn">
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
          <Breadcrumb rootRouteName="home" />
        </v-toolbar-title>
      </div>

      <v-spacer />
      <v-toolbar-items>
        <v-flex align-center layout py-2>
          <v-btn to="/admin/user-profile" icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>

          <v-btn icon @click="logoutAttempt">
            <v-icon>mdi-power</v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import Breadcrumb from './Breadcrumb';
export default {
  components: {
    Breadcrumb,
  },
  data: () => ({
    title: null,
    responsive: false,
  }),
  watch: {
    $route(val) {
      this.title = val.name;
    },
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },
  created() {},
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    ...mapActions('auth', ['LOGOUT']),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onClick() {
      //
    },
    logoutAttempt() {
      this.LOGOUT();
      this.$router.push({ path: '/login' });
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
  computed: {},
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
.v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items {
  padding: 10px 15px;
}
.v-toolbar__title {
  display: flex;
  align-items: center;
}
</style>
