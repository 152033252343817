<template>
  <ckeditor
    v-model="getValue"
    :config="editorConfig"
    :editor-url="editorUrl"
    @input="$emit('input', $event)"
  ></ckeditor>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
export default {
  name: 'editor',
  components: {
    // Use the <ckeditor> component in this view.
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editorUrl: '/ckeditor/ckeditor.js',
      editorConfig: {
        filebrowserBrowseUrl: '/file_manager',
        filebrowserUploadUrl: '/file_manager_upload',
        filebrowserUploadMethod: 'xhr',
        fileTools_requestHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: '',
        },
        toolbarGroups: [
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'undo', groups: ['undo'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          { name: 'paragraph', groups: ['align'] },
          {
            name: 'paragraph1',
            groups: ['list', 'blocks', 'align', 'paragraph'],
          },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'tools', groups: ['tools'] },
        ],
        removeButtons:
          'Save,NewPage,ExportPdf,Preview,Print,Templates,Subscript,Superscript,CopyFormatting,RemoveFormat,Anchor,Unlink,Outdent,Indent',
      },
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    token() {
      return `Bearer ` + localStorage.getItem('token');
    },
  },
  methods: {},
  created() {
    if (process.env.NODE_ENV === 'production') {
      this.editorUrl = '/ckeditor/ckeditor.js';
      this.editorConfig.filebrowserUploadUrl = '/api/file_manager_upload';
      this.editorConfig.fileTools_requestHeaders = {
        Authorization: this.token,
      };
    } else {
      this.editorUrl = 'http://localhost:8095/ckeditor/ckeditor.js';
      this.editorConfig.filebrowserUploadUrl = 'http://localhost:8095/api/file_manager_upload';
      this.editorConfig.fileTools_requestHeaders = {
        Authorization: this.token,
      };
    }
  },
};
</script>

<style scoped></style>
