<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span
          >Баланс на TURBO SMS: <b>{{ this.Balance }}</b> гривен.</span
        >
      </v-col>
      <v-col cols="12">
        <v-checkbox v-model="Enabled" label="Включить отправку СМС"></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="Token" label="HTTP Token Turbo SMS"> </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="AlphaName" label="Имя Альфа отправителя"> </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="SmsText"
          counter="250"
          label="Текст смс (в конец текста будет добавлена ссылка на гарантийный талон ~ 52 символа"
        >
        </v-text-field>
        <div class="mt-3">
          <span
            >* Максимальная длинна смс в TurboSMS 160 символов латиницей(в том числе пробелы и
            другие знаки) или 70 символов кирилицей(в том числе пробелы и другие знаки). Ввиду того
            что длина ссылки на гарантийный талон ~ 52 символа(при адресе сайта
            "https://service.einhell.ua") рекомендуется писать текст сообщения на латинице, так как
            при наличии хоть 1 кирилического символа сообщение будет считатся кирилическим и
            ограничение будет 70 символов(из которых 52 ссылка на гарантию)
          </span>
        </div>
      </v-col>
      <div style="width: 100%" class="d-flex justify-center">
        <v-btn class="mt-3" @click="SetTurboSms">Сохранить</v-btn>
      </div>
    </v-row>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';

export default {
  data() {
    return {
      Token: '',
      Balance: '',
      SmsText: '',
      AlphaName: '',
      Enabled: false,
    };
  },
  computed: {},
  async created() {
    await this.getTurboSms();
    await this.getBalance();
  },
  methods: {
    async refresh() {
      await this.getTurboSms();
      await this.getBalance();
    },
    async getTurboSms() {
      await HTTP.get('turbo_sms').then(
        (response) => {
          this.Token = response.data.Token;
          this.SmsText = response.data.SmsText;
          this.AlphaName = response.data.AlphaName;
          this.Enabled = response.data.Enabled;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async getBalance() {
      await HTTP.get('turbo_sms/balance').then(
        (response) => {
          if (response.data.response_status === 'OK') {
            this.Balance = response.data.response_result.balance.toString();
          } else if (response.data.response_status === 'INVALID_TOKEN') {
            this.Balance = 'Неправильный токен';
          } else {
            this.Balance = 'Не могу получить баланс!';
          }
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async SetTurboSms() {
      await HTTP.post('turbo_sms', {
        Token: this.Token,
        SmsText: this.SmsText,
        AlphaName: this.AlphaName,
        Enabled: this.Enabled,
      }).then(
        () => {
          this.refresh();
          this.$toastr.s('Изменено успешно');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped></style>
