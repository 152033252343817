<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-row no-gutters>
          <v-col cols="12" sm="10" class="pt-0 pb-0">
            <v-card class="mr-1">
              <v-overlay absolute :value="$wait.is('user')" opacity="1" color="white">
                <v-progress-circular indeterminate size="64" color="primary" />
              </v-overlay>
              <v-container fluid v-bind:class="{ invisible: $wait.is('user') }">
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <utils-input-with-button
                      required
                      :opened-input-var="openedInput"
                      v-model="userVuex.Surname"
                      label="Фамилия"
                      :loader="$wait.is('userChangeData-Surname')"
                      @callback="changeUserData($event, 'Surname')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <utils-tels-many-with-button
                      :opened-input-var="openedInput"
                      v-model="userVuex.Tels"
                      label="Телефон"
                      :loader="$wait.is('userChangeData-Tels')"
                      maskLabel="Формат"
                      :canSelectType="true"
                      @callback="changeUserData($event, 'Tels')"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <div class="label">
                      <span><b>Статус:</b></span>
                    </div>
                    <div style="height: 50px">
                      <span v-if="userVuex.Active">
                        <v-chip color="green" label text-color="white"> Активен </v-chip>
                      </span>
                      <span v-else>
                        <v-chip color="red" label text-color="white"> Заблокирован </v-chip>
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <utils-input-with-button
                      required
                      :opened-input-var="openedInput"
                      v-model="userVuex.Name"
                      label="Имя"
                      :loader="$wait.is('userChangeData-Name')"
                      @callback="changeUserData($event, 'Name')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <utils-input-with-button
                      :opened-input-var="openedInput"
                      v-model="userVuex.Email"
                      label="Email"
                      isEmail
                      :loader="$wait.is('userChangeData-Email')"
                      @callback="changeUserData($event, 'Email')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <div class="label pb-1">
                      <span><b>Права:</b></span>
                    </div>
                    <div style="height: 50px">
                      <v-checkbox
                        v-model="userVuex.Admin"
                        label="Администратор"
                        class="pt-0 pb-0 mt-0"
                        @change="setUserRights($event, 'Admin')"
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <utils-input-with-button
                      required
                      :opened-input-var="openedInput"
                      v-model="userVuex.Fathersname"
                      label="Отчество"
                      :loader="$wait.is('userChangeData-Fathersname')"
                      @callback="changeUserData($event, 'Fathersname')"
                      @inputBoxSwitch="openedInput = !openedInput"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="2" sm="2" class="pt-0 pb-0">
            <v-card min-height="100%">
              <v-row align="center">
                <v-col class="text-center" cols="12">
                  <v-dialog v-model="dialogChangePassword" persistent max-width="500">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-bind:color="'green'"
                        class="ma-3 multiline"
                        v-on="on"
                        @click="openChangePasswordDialog"
                      >
                        <span class="white--text">Сменить пароль</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline justify-center align-center">
                        Введите новый пароль
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="changePassword" v-model="valid" lazy-validation>
                            <v-row>
                              <v-col cols="12" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                  label="*Пароль"
                                  required
                                  :rules="passwordRules"
                                  v-model="Password"
                                  counter
                                  :type="showPassword ? 'text' : 'password'"
                                  ref="passwordInput"
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      tabindex="-1"
                                      @click="showPassword = !showPassword"
                                      v-if="showPassword"
                                      >mdi-eye</v-icon
                                    >
                                    <v-icon
                                      @click="showPassword = !showPassword"
                                      tabindex="-1"
                                      v-if="!showPassword"
                                      >mdi-eye-off</v-icon
                                    >
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" class="pt-0 pb-0">
                                <v-text-field
                                  label="*Повторите пароль"
                                  required
                                  :rules="passwordRepeatRules"
                                  v-model="PasswordRepeat"
                                  counter
                                  :type="showPasswordRepeat ? 'text' : 'password'"
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      tabindex="-1"
                                      @click="showPasswordRepeat = !showPasswordRepeat"
                                      v-if="showPasswordRepeat"
                                      >mdi-eye</v-icon
                                    >
                                    <v-icon
                                      @click="showPasswordRepeat = !showPasswordRepeat"
                                      tabindex="-1"
                                      v-if="!showPasswordRepeat"
                                      >mdi-eye-off</v-icon
                                    >
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                class="pt-0 pb-0 text-center"
                                v-if="passwordGenerated"
                              >
                                <span
                                  >Сгенерированный пароль:
                                  <span class="blue--text"
                                    ><b>{{ passwordGenerated }}</b></span
                                  ></span
                                >
                              </v-col>
                              <v-col cols="12" sm="12" class="pt-0 pb-0">
                                <v-btn text block @click="generatePassword">
                                  Сгенерировать пароль
                                  <v-icon class="ml-5">mdi-lock-reset</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                        <small>*обязательно к заполнению</small>
                        <v-row>
                          <v-col cols="12" sm="12" class="pt-0 pb-0">
                            <small class="error--text"
                              >**При смене пароля все сеансы этого пользователя будут принудительно
                              завершены!</small
                            >
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn color="primary" text @click="dialogChangePassword = false">
                          Отменить
                        </v-btn>
                        <v-spacer />
                        <v-btn color="primary" text @click="changePassword" :disabled="!valid">
                          <span>Сохранить</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col class="text-center" cols="12">
                  <v-dialog v-model="dialogBlock" persistent max-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-bind:color="userVuex.Active ? 'error' : 'primary'"
                        class="ma-3 multiline"
                        v-on="on"
                      >
                        <span v-if="userVuex.Active">Заблокировать сотрудника</span>
                        <span v-else>Разблокировать сотрудника</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline justify-center align-center">
                        Вы уверены?
                      </v-card-title>
                      <v-card-actions>
                        <v-btn color="primary" text @click="dialogBlock = false"> Отменить </v-btn>
                        <v-spacer />
                        <v-btn color="error" text @click="blockUnblockUser">
                          <span v-if="userVuex.Active">Заблокировать</span>
                          <span v-else>Разблокировать</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col class="text-center" cols="12">
                  <v-dialog v-model="dialogDelete" persistent max-width="250">
                    <template v-slot:activator="{ on }">
                      <v-btn color="error" class="ma-3 multiline" v-on="on">
                        Удалить сотрудника
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline justify-center align-center">
                        Вы уверены?
                      </v-card-title>
                      <v-card-actions>
                        <v-btn color="primary" text @click="dialogDelete = false"> Отменить </v-btn>
                        <v-spacer />
                        <v-btn color="error" text @click="deleteUser"> Удалить </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      id: parseInt(this.$route.params.id),
      openedInput: false,
      dialogDelete: false,
      dialogBlock: false,
      dialogChangePassword: false,
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      passwordRules: [
        (v) => {
          if (!v) return false;
          return (
            this.passwordRegex.test(v) ||
            'Минимум 8 символов, 1 большая буква, 1 маленькая буква, 1 цифра'
          );
        },
      ],
      passwordRepeatRules: [
        (v) => {
          return v === this.Password || 'Пароли не совпадают';
        },
      ],
      valid: true,
      showPassword: false,
      showPasswordRepeat: false,
      passwordGenerated: false,
      Password: '',
      PasswordRepeat: '',
    };
  },
  computed: {
    userVuex() {
      return this.$store.getters['users/USER'];
    },
  },
  methods: {
    async changeUserData(value, field) {
      this.$wait.start('userChangeData-' + field);
      await this.$store
        .dispatch('users/CHANGE_USER_DATA', {
          id: this.$route.params.id,
          value,
          field,
        })
        .then(
          () => {
            this.$toastr.s('Изменено успешно');
            this.$store.dispatch('users/GET_USER_BY_ID', this.$route.params.id);
          },
          (error) => {
            let message;
            switch (true) {
              case error === '404':
                message = 'Сотрудник не существует или был удален';
                this.$router.push({ path: this.$store.state.app.backURL });
                break;
              case error.includes('Error 1062: Duplicate entry'):
                message = 'Сотрудник с таким email уже существует';
                break;
              default:
                message = error;
                break;
            }
            this.$toastr.e(message, 'Произошла ошибка');
          }
        );

      this.$wait.end('userChangeData-' + field);
    },
    breadcrumbsStart() {
      this.$store.dispatch('app/SET_BREADCRUMBS', [
        {
          text: 'Главная',
          to: '/admin',
          disabled: false,
        },
        {
          text: 'Сотрудники',
          to: '/admin/users',
          disabled: false,
        },
      ]); // что б не прыгало название отдаем часть в начале, потом меняем
    },
    async setBreadcrumbs() {
      await this.$store.dispatch('app/SET_BREADCRUMBS', [
        {
          text: 'Главная',
          to: '/admin',
          disabled: false,
        },
        {
          text: 'Сотрудники',
          to: '/admin/users',
          disabled: false,
        },
        {
          text: this.userVuex.Surname + ' ' + this.userVuex.Name + ' ' + this.userVuex.Fathersname,
          to: '',
          disabled: true,
        },
      ]);
    },
    async deleteUser() {
      await this.$store.dispatch('users/DELETE_USER', this.$route.params.id).then(
        () => {
          this.$toastr.s(
            'Сотрудник "' +
              this.userVuex.Surname +
              ' ' +
              this.userVuex.Name +
              ' ' +
              this.userVuex.Fathersname +
              '" удален'
          );
          this.$router.push({ path: this.$store.state.app.backURL });
        },
        (error) => {
          if (error.includes('Error 1451:')) {
            this.$toastr.e('Есть зависимые элементы', 'Невозможно удалить');
            this.dialogDelete = false;
          } else {
            this.$toastr.e(error, 'Произошла ошибка');
            this.$router.push({ path: this.$store.state.app.backURL });
          }
        }
      );
    },
    async blockUnblockUser() {
      let value;
      if (this.userVuex.Active) {
        value = '0';
      } else {
        value = '1';
      }
      await this.$store
        .dispatch('users/CHANGE_USER_DATA', {
          id: this.$route.params.id,
          value,
          field: 'Active',
        })
        .then(
          () => {
            this.$store.dispatch('users/GET_USER_BY_ID', this.$route.params.id);
          },
          (error) => {
            if (error === '404') {
              this.$toastr.e('Сотрудник не существует или был удален', 'Произошла ошибка');
            } else {
              this.$toastr.e(error, 'Произошла ошибка');
            }
          }
        );
      this.dialogBlock = false;
    },
    async setUserRights() {
      let value;
      if (this.userVuex.Admin) {
        value = '1';
      } else {
        value = '0';
      }
      await this.changeUserData(value, 'Admin');
    },
    openChangePasswordDialog() {
      if (this.$refs.changePassword) {
        this.Password = '';
        this.PasswordRepeat = '';
        this.showPassword = false;
        this.showPasswordRepeat = false;
        this.passwordGenerated = false;
        this.$refs.changePassword.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.passwordInput.focus();
          });
        });
      });
    },
    generatePassword() {
      let once = false;
      let newPassword = Math.random()
        .toString(36)
        .substring(2, 10)
        .split('')
        .map((char) => {
          if (!Number(char) && !once && char !== '0') {
            once = true;
            return char.toUpperCase();
          }
          return char;
        })
        .join('');

      once = false;
      if (!/\d/.test(newPassword)) {
        newPassword = newPassword
          .substring(0, 10)
          .split('')
          .map((char) => {
            if (char !== char.toUpperCase() && !once) {
              once = true;
              return Math.floor(Math.random() * Math.floor(10)).toString();
            }
            return char;
          })
          .join('');
      }
      this.Password = newPassword;
      this.PasswordRepeat = newPassword;
      this.passwordGenerated = newPassword;
    },
    changePassword() {
      if (this.$refs.changePassword.validate()) {
        this.changeUserData(this.Password, 'NewPassword');
        this.dialogChangePassword = false;
      }
    },
  },
  async created() {
    this.breadcrumbsStart();
    this.$wait.start('user');
    await this.$store.dispatch('users/GET_USER_BY_ID', this.$route.params.id).then(
      () => {
        this.setBreadcrumbs(this.userVuex.Name);
        this.$wait.end('user');
      },
      (error) => {
        if (error === '404') {
          this.$toastr.e('Сотрудник не существует или был удален', 'Произошла ошибка');
        } else {
          this.$toastr.e('Неизвестная ошибка');
        }
        this.$router.push({ path: this.$store.state.app.backURL });
      }
    );
  },
};
</script>
<style lang="css">
.invisible {
  opacity: 0;
}

.multiline {
  white-space: normal !important;
}
.multiline .v-btn__content {
  flex: 0 !important;
}
.multiline::before {
  flex: 0 !important;
}
</style>
