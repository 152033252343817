<template>
  <v-autocomplete
    @change="$emit('input', localValue)"
    v-model="getValue"
    :items="items"
    :label="label"
    :loading="$wait.is('selectUser')"
    no-data-text="Ничего не найдено"
    :disabled="disabled"
    :dense="dense"
    :attach="attach"
    :menu-props="menuProps"
  >
    <v-btn v-if="!disabled" slot="append-outer" icon @click="refresh" tabindex="-1">
      <v-icon> mdi-refresh </v-icon>
    </v-btn>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'selectUser',
  data() {
    return {
      localValue: this.value,
    };
  },
  props: {
    value: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    disableEmpty: {
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    dense: Boolean,
    attach: String,
    menuProps: Object,
  },
  methods: {
    async refresh() {
      this.$wait.start('selectUser');
      await this.$store.dispatch('utils/selectUser/GET_USERS').catch(() => {}); // если нет хоть какой то обработки ошибки - функция дальше не выполняеться;
      this.$wait.end('selectUser');
    },
  },
  created() {
    this.refresh();
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    items() {
      let options = [];
      if (!this.disableEmpty) {
        options.push({ value: null, text: 'Не назначено' });
      }
      this.UsersArray.forEach(function (item) {
        options.push({
          value: item.Id,
          text: item.Surname + ' ' + item.Name + ' ' + item.Fathersname,
        });
      });
      return options;
    },
    UsersArray() {
      return this.$store.getters['utils/selectUser/USERS'];
    },
  },
};
</script>

<style lang="css" scoped></style>
