<template>
  <v-app app>
    <router-view> </router-view>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
.v-application {
  line-height: 1 !important;
}
.toastr-custom {
  opacity: 1;
}
.toastr-custom .toast-title {
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
}
.toastr-custom .toast-message {
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
}

.v-application {
  background: #e4e5e6 !important;
}

blockquote {
  padding-left: 20px !important;
  padding-right: 8px !important;
  border-left-width: 5px !important;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
  margin: 1em;
}

blockquote p {
  margin: 1em;
}
</style>
