<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-card>
          <v-tabs>
            <v-tab ripple @change="refreshSKU"> Артикулы </v-tab>
            <v-tab ripple @change="refreshWhereToBuy"> Где купили </v-tab>
            <v-tab ripple @change="refreshDataPrivacy"> Защита данных </v-tab>
            <v-tab ripple @change="refreshWarrantySpec"> Условия Гарантии </v-tab>
            <v-tab ripple @change="refreshEmailContent"> Настройки письма </v-tab>
            <v-tab ripple @change="refreshTurboSms"> Turbo SMS </v-tab>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <SKUTable ref="sku_table" />
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <WhereToBuyTable ref="where_to_buy_table" />
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <DataPrivacy ref="data_privacy"></DataPrivacy>
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <WarrantySpec ref="warranty_spec"></WarrantySpec>
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <EmailContent ref="email_content"></EmailContent>
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <TurboSms ref="turbo_sms"></TurboSms>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import SKUTable from './SKU/Index';
import WhereToBuyTable from './WhereToBuy/Index';
import DataPrivacy from './DataPrivacy/Index';
import WarrantySpec from './WarrantySpec/Index';
import EmailContent from './Email/Index';
import TurboSms from './TurboSms/Index';

export default {
  components: {
    SKUTable,
    WhereToBuyTable,
    DataPrivacy,
    WarrantySpec,
    EmailContent,
    TurboSms,
  },
  data() {
    return {};
  },
  methods: {
    refreshSKU() {
      if (this.$refs.sku_table) {
        this.$refs.sku_table.refresh();
      }
    },
    refreshWhereToBuy() {
      if (this.$refs.where_to_buy_table) {
        this.$refs.where_to_buy_table.refresh();
      }
    },
    refreshDataPrivacy() {
      if (this.$refs.data_privacy) {
        this.$refs.data_privacy.refresh();
      }
    },
    refreshWarrantySpec() {
      if (this.$refs.warranty_spec) {
        this.$refs.warranty_spec.refresh();
      }
    },
    refreshEmailContent() {
      if (this.$refs.email_content) {
        this.$refs.email_content.refresh();
      }
    },
    refreshTurboSms() {
      if (this.$refs.turbo_sms) {
        this.$refs.turbo_sms.refresh();
      }
    },
  },
};
</script>
