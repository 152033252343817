<template>
  <div class="selectUserWithButton">
    <div class="label">
      <span>
        <b> {{ label }}: </b>
      </span>
      <span class="mdi mdi-square-edit-outline input-toggler" @click="inputBoxShow" />
    </div>
    <div style="height: 54px">
      <div v-if="!loader">
        <span v-if="userName !== '' && userName !== undefined">
          {{ userName }}
        </span>
        <span v-else>[Не задано]</span>
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
    <div v-if="inputShow">
      <div class="changeable_holder" v-if="inputShow" id="changeable_holder_select_user">
        <div class="select_user" id="select_user">
          <v-form v-on:submit.prevent="">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <utils-select-user
                  ref="select_user"
                  v-model="localValue"
                  label=""
                  attach="#select_user"
                  :menu-props="{ offsetY: true, nudgeBottom: 21, offsetX: false, nudgeLeft: -16 }"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-btn color="blue darken-1" class="float-left" text @click="inputBoxHide">
                  Отменить
                </v-btn>
                <v-btn color="blue darken-1" class="float-right" text @click="call_Callback">
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectUserWithButton',
  data() {
    return {
      valid: true,
      localValue: null,
      inputShow: false,
      escapeValue: '',
    };
  },
  props: {
    value: {
      type: Number,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    userName: {
      type: String,
      required: true,
    },
    openedInputVar: {
      type: Boolean,
      required: true,
    },
    loader: {
      type: Boolean,
      required: false,
    },
    disableEmpty: {
      type: Boolean,
    },
  },
  created() {
    this.localValue = this.value;
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
  },
  methods: {
    inputBoxShow() {
      if (!this.openedInputVar) {
        this.escapeValue = this.value;
        this.inputShow = true;
        this.localValue = this.value;
        document.addEventListener('mousedown', this.inputBoxHideByOuterClick, false);
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.select_user.refresh();
          });
        });
      } else {
        this.$toastr.e('Есть открытые и не заполненнные элементы');
      }
    },
    inputBoxHide() {
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },
    inputBoxHideByOuterClick(e) {
      let _root = document.getElementById('changeable_holder_select_user');
      let isContain = _root.contains(e.target);
      if (isContain) {
        return;
      }
      document.removeEventListener('mousedown', this.inputBoxHideByOuterClick, false);
      this.inputShow = false;
    },

    call_Callback() {
      this.inputBoxHide();
      if (this.localValue === this.value) {
        return; // Если значение не поменялось - ничего не делаем
      }
      this.$emit('callback', this.localValue);
    },
    escape() {
      this.localValue = this.escapeValue;
      this.inputShow = false;
    },
  },
};
</script>

<style scoped>
.selectUserWithButton {
  position: relative;
}
.changeable_holder {
  position: absolute;
  left: -17px;
  top: 25px;
  min-width: 300px;
  z-index: 10;
  background: #fff;
  margin: 0px 15px;
}

.select_user {
  box-sizing: border-box;
  margin: 0;
  padding: 15px;
  border: 1px solid #dadfe6;
}

.input-toggler {
  cursor: pointer;
  padding-left: 8px;
}

.label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
</style>
