<template>
  <div>
    <utils-ckeditor v-model="warrantySpec" />
    <v-btn class="mt-3" @click="setWarrantySpec">Сохранить</v-btn>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';

export default {
  data() {
    return {
      warrantySpec: '',
    };
  },
  computed: {},
  async created() {
    await this.getWarrantySpec();
  },
  methods: {
    async refresh() {
      await this.getWarrantySpec();
    },
    async getWarrantySpec() {
      await HTTP.get('warranty_spec').then(
        (response) => {
          this.warrantySpec = response.data.Data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async setWarrantySpec() {
      await HTTP.post('warranty_spec', { data: this.warrantySpec }).then(
        () => {
          this.refresh();
          this.$toastr.s('Изменено успешно');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped></style>
