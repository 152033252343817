import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Vuetify from 'vuetify/lib';
import './components/components';
import '@/utils/app/components/';

import '@/utils/plugins/notifier/toastr';
import '@/utils/plugins/vue-wait/index';
import wait from '@/utils/plugins/vue-wait/index';

import UniqueId from 'vue-unique-id';
Vue.use(UniqueId);

Vue.use(Vuetify);
const vuetify = new Vuetify({});

import VueConfirmDialog from 'vue-confirm-dialog';

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

const defaultOptions = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href', 'name', 'target', 'style'],
    // We don't currently allow img itself by default, but this
    // would make sense if we did. You could add srcset here,
    // and if you do the URL is checked for safety
    img: ['src', 'style'],
    p: ['style'],
    li: ['style'],
    table: ['style'],
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
};

import VSanitize from 'v-sanitize';
Vue.use(VSanitize, defaultOptions);
Vue.use(require('vue-moment'));
import axios from 'axios';

let authHeader = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = authHeader;

const app = {
  router,
  store,
  vuetify,
  wait,
  render: (h) => h(App),
};

new Vue(app).$mount('#app');
