let Production_url = window.location.origin + '/api/';
let Production_loginUrl = window.location.origin + '/login'; //rudiment
let Production_websocketUrl = window.location.origin + '/';

let Development_url = 'http://localhost:8095/api/';
let Development_loginUrl = 'http://localhost:8095/login';
let Development_websocketUrl = 'http://localhost:8095/';

function url() {
  if (process.env.NODE_ENV === 'production') return Production_url;
  else {
    return Development_url;
  }
}

function loginUrl() {
  if (process.env.NODE_ENV === 'production') return Production_loginUrl;
  else {
    return Development_loginUrl;
  }
}

function websocketUrl() {
  if (process.env.NODE_ENV === 'production') return Production_websocketUrl;
  else {
    return Development_websocketUrl;
  }
}

function socketioDebug() {
  return process.env.NODE_ENV !== 'production';
}

export { url, loginUrl, websocketUrl, socketioDebug };
