import { HTTP } from '@/utils/plugins/axios/axios';

const state = {
  users: [],
};

const getters = {
  USERS: (state) => {
    return state.users;
  },
};

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
};

const actions = {
  GET_USERS: async ({ commit }) => {
    await HTTP.get('users').then(
      (response) => {
        commit('SET_USERS', response.data);
      },
      (error) => {
        if (error.response.data.Error) {
          throw error.response.data.Error;
        }
        throw error;
      }
    );
  },
};

const selectUsers = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default selectUsers;
