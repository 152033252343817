<template>
  <v-breadcrumbs :items="breadcrumbsList">
    <template #item="{ item }">
      <v-breadcrumbs-item
        :active-class="item.to !== $route.path ? '' : undefined"
        :disabled="item.disabled"
        :href="item.href"
        :link="item.link"
        :to="item.to"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: {
    items: {
      type: Array,
      default: null,
    },
    rootRouteName: {
      type: String,
      default: 'home',
    },
  },
  computed: {
    breadcrumbsList() {
      let arrayBC = this.$route.matched.filter((route) => route.name === this.$route.name);
      let breadcrumbsEmpty = [];
      if (this.$route.name === this.rootRouteName) {
        breadcrumbsEmpty.unshift({
          text: 'Главная',
          to: '/admin',
          disabled: true,
        });
        return breadcrumbsEmpty;
      }
      breadcrumbsEmpty.unshift({
        text: arrayBC[0].meta.label,
        to: arrayBC[0].path,
        disabled: true,
      });

      if (arrayBC[0].parent) {
        if (arrayBC[0].parent.path !== '' || arrayBC[0].parent.path !== '/admin') {
          this.formBreadcrumbListRecursive(arrayBC[0].parent, breadcrumbsEmpty);
        } else {
          breadcrumbsEmpty.unshift({
            text: 'Главная',
            to: '/admin',
            disabled: false,
          });
        }
      }
      if (this.$store.state.app.breadcrumbs) {
        return this.$store.state.app.breadcrumbs;
      }
      return breadcrumbsEmpty;
    },
  },
  methods: {
    formBreadcrumbListRecursive(objectBC, breadcrumbs) {
      breadcrumbs.unshift({
        text: objectBC.meta.label,
        to: objectBC.path,
        disabled: false,
      });
      if (objectBC.parent) {
        if (objectBC.parent.path !== '' || objectBC.parent.path !== '/admin') {
          this.formBreadcrumbListRecursive(objectBC.parent, breadcrumbs);
        } else {
          breadcrumbs.unshift({
            text: 'Главная',
            to: '/admin',
            disabled: false,
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
