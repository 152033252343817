import Home from './routes/home/Index.vue';
import Users from './routes/users/Index.vue';
import UserEdit from './routes/users/Edit/Index.vue';
import UserProfile from './routes/profile/Index.vue';
import Settings from './routes/settings/Index.vue';
import Email from './routes/settings/email/Index.vue';
import Site from './routes/settings/site/Index.vue';

import References from './routes/references/Index.vue';
import WarrantyRequests from './routes/warrantyRequests/Index.vue';
import Storage from './routes/storage/Index.vue';

let appRoutes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: 'warranty-requests',
    name: 'WarrantyRequests',
    meta: {
      label: 'Гарантии',
    },
    component: WarrantyRequests,
  },
  {
    path: 'storage',
    name: 'storage',
    meta: {
      label: 'Хранилище файлов',
    },
    component: Storage,
  },
  {
    path: 'references',
    name: 'references',
    meta: {
      label: 'Справочники',
    },
    component: References,
  },
  {
    path: 'users',
    name: 'Users',
    meta: {
      label: 'Сотрудники',
      guard: 'FULL-PERMISSIONS',
    },
    component: Users,
  },
  {
    path: 'users/:id',
    name: 'EditUser',
    meta: {
      label: 'Сотрудники',
      guard: 'FULL-PERMISSIONS',
    },
    component: UserEdit,
  },
  {
    path: 'user-profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: 'settings',
    name: 'Settings',
    component: Settings,
    children: [
      {
        path: 'email',
        component: Email,
      },
      {
        path: 'site',
        component: Site,
      },
    ],
  },
];

export default appRoutes;
