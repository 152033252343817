import { HTTP, HTTPLogin } from '@/utils/plugins/axios/axios';

const actions = {
  LOGIN({ commit }, credential) {
    return new Promise((resolve, reject) => {
      HTTPLogin.post('auth/login', credential)
        .then((response) => {
          commit('LOGIN', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  LOGOUT({ commit }) {
    commit('logout');
    HTTP.post('auth/logout');
  },
  NETWORK_ERROR({ commit }, isError) {
    commit('networkError', isError);
  },
  REFRESH_TOKEN({ commit }, data) {
    commit('REFRESH_TOKEN', data);
  },
};

export default actions;
