<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="Subject" label="Тема письма"> </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="PdfTitle" label="Название в PDF файле"> </v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="mb-2">Текст письма:</div>
        <utils-ckeditor v-model="Text" />
      </v-col>
      <div style="width: 100%" class="d-flex justify-center">
        <v-btn class="mt-3" @click="setEmailContent">Сохранить</v-btn>
      </div>
    </v-row>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';

export default {
  data() {
    return {
      Subject: '',
      Text: '',
      PdfTitle: '',
    };
  },
  computed: {},
  async created() {
    await this.getEmailContent();
  },
  methods: {
    async refresh() {
      await this.getEmailContent();
    },
    async getEmailContent() {
      await HTTP.get('email_content').then(
        (response) => {
          response.data.forEach((el) => {
            switch (el.Name) {
              case 'subject':
                this.Subject = el.Data;
                break;
              case 'text':
                this.Text = el.Data;
                break;
              case 'pdf_title':
                this.PdfTitle = el.Data;
                break;
            }
          });
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async setEmailContent() {
      await HTTP.post('email_content', [
        { name: 'subject', data: this.Subject },
        { name: 'text', data: this.Text },
        { name: 'pdf_title', data: this.PdfTitle },
      ]).then(
        () => {
          this.refresh();
          this.$toastr.s('Изменено успешно');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped></style>
