<template>
  <v-container fluid fill-height pb-10>
    <v-layout wrap>
      <v-flex md12>
        <v-card>
          <v-tabs>
            <v-tab ripple @change="getFiles"> Хранилище Файлов </v-tab>
            <v-tab-item>
              <v-card>
                <v-container fluid fill-width pb-10>
                  <v-row>
                    <v-col cols="10">
                      <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        v-model="searchString"
                        label="Фильтр"
                        clearable
                      />
                    </v-col>
                    <v-col cols="2" sm="2">
                      <v-btn
                        class="mx-2 float-right mt-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="handleFileImport"
                      >
                        <v-icon dark>mdi-paperclip</v-icon>
                      </v-btn>

                      <input
                        @change="handleFileUpload($event)"
                        ref="FileInput"
                        type="file"
                        class="d-none"
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="searchString"
                    :loading="$wait.is('files')"
                    loading-text="Загрузка... Пожалуйста подождите"
                    hide-default-footer
                    :page.sync="page"
                    :items-per-page="100"
                    @page-count="pageCount = $event"
                    @current-items="currentItemsInfo = $event"
                    item-key="Id"
                    no-data-text="Ничего не найдено"
                    no-results-text="Ничего не найдено"
                  >
                    <template v-slot:item.Link="{ item }">
                      <span>{{ formFileLink(item) }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon class="mr-4" @click="openFile(item)"> mdi-eye </v-icon>
                      <v-icon class="mr-4" @click="DeleteFile(item)"> mdi-delete </v-icon>
                    </template>
                  </v-data-table>

                  <div class="pt-12" v-if="items.length > 0">
                    <div v-if="pageCount > 1" class="text-center pt-2">
                      <v-pagination v-model="page" :length="pageCount" />
                    </div>
                    <div class="text-center pt-2">
                      <span v-if="items.length > currentItemsInfo.length">
                        На странице: {{ currentItemsInfo.length }}
                      </span>
                      <br />
                      <span>Всего: {{ items.length }}</span>
                    </div>
                  </div>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';
export default {
  components: {},
  data() {
    return {
      headers: [
        { value: 'Name', text: 'Имя', sortable: true, filterable: true },
        { value: 'Link', text: 'Ссылка', sortable: false, filterable: false },
        { value: 'Size', text: 'Размер', sortable: true, filterable: true },
        { value: 'ChangedDate', text: 'Создан', sortable: true, filterable: true },
        { text: '', value: 'actions', sortable: false },
      ],
      currentItemId: 0,
      items: [],
      page: 1,
      pageCount: 0,
      currentItemsInfo: [],
      searchString: '',
      UploadFileArray: [],
    };
  },
  computed: {},
  mounted() {},
  async created() {
    await this.getFiles();
  },
  methods: {
    async handleFileUpload(event) {
      let vm = this;
      let formData = new FormData();
      if (event.target.files.length > 0) {
        event.target.files.forEach((el) => {
          formData.append('file', el);
        });
        await HTTP.post('storage/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          () => {
            this.getFiles();
            this.$toastr.s('Файлы загружены');
            vm.$refs.FileInput.value = '';
          },
          (error) => {
            vm.$refs.FileInput.value = '';
            if (error.response.data.Error) {
              if (error.response.data.Error.includes('already present in storage')) {
                this.$toastr.e('Такие файлы уже есть в хранилище');
              } else {
                this.$toastr.e(error.response.data.Error);
              }
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    handleFileImport() {
      this.$refs.FileInput.click();
    },
    formFileLink(item) {
      return 'http://' + location.host + '/storage/' + item.Name;
    },
    openFile(item) {
      window.open(this.formFileLink(item), '_blank');
    },
    async getFiles() {
      this.$wait.start('files');
      await HTTP.get('storage').then(
        (response) => {
          this.$wait.end('files');
          this.items = response.data;
        },
        (error) => {
          this.$wait.end('files');
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async DeleteFile(item) {
      await HTTP.post('storage/delete', item.Name).then(
        () => {
          this.$toastr.s('Удалено успешно!');
          this.getFiles();
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>
<style lang="css" scoped>
.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
