<template>
  <div style="width: 100%" class="d-flex">
    <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
      <v-subheader class="ml-0 pl-0">Серійний номер</v-subheader>
    </v-col>
    <v-col v-if="allowDelete" cols="8" class="pt-0 pb-0">
      <v-text-field
        single-line
        outlined
        dense
        v-model="getValue"
        :rules="serialRules"
        clearable
        :color="inputColorAndClassSerial.color"
        :class="inputColorAndClassSerial.class"
        @input.native="handleInput"
        append-outer-icon="mdi-close"
        @click:append-outer="deleteSelf"
        v-mask="'XXXXXXX'"
      ></v-text-field>
    </v-col>
    <v-col v-else cols="8" class="pt-0 pb-0">
      <v-text-field
        single-line
        outlined
        dense
        v-model="getValue"
        :rules="serialRules"
        clearable
        :color="inputColorAndClassSerial.color"
        :class="inputColorAndClassSerial.class"
        v-mask="'XXXXXXX'"
        @input.native="handleInput"
      ></v-text-field>
    </v-col>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
export default {
  directives: {
    mask,
  },
  name: 'serial',
  data() {
    return {
      valid: false,
      localValue: '',
      serialRules: [
        (v) => {
          if (!v) return "Cерійний номер обов'язкове поле!";
          if (v.length < 7) return 'Введіть серійний номер повністю';
          return true;
        },
      ],
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    inputColorAndClassSerial() {
      if (this.localValue && this.localValue.length > 6) {
        return { color: 'green', class: 'success-input' };
      } else {
        return { color: 'red', class: 'error-input' };
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue);
    },
    deleteSelf() {
      this.$emit('deleteSelf', this.index);
    },
  },
  created() {},
};
</script>

<style scoped></style>
