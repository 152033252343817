<template>
  <div>
    <v-row v-if="!NumberType" class="pr-0 pl-0 mt-0">
      <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
        <div :id="$id('select_tel_select')">
          <v-select
            v-model="mask"
            :attach="attachStringMask"
            :items="[
              {
                text: '(###) ###-##-##',
                value: { mask: '(###) ###-##-##', tokens: this.hexTokens },
              },
              {
                text: '(###-##) #-##-##',
                value: { mask: '(###-##) #-##-##', tokens: this.hexTokens },
              },
              {
                text: 'Без маски',
                value: { mask: 'P################', tokens: this.hexTokens },
              },
            ]"
            :label="maskLabel"
            @change="setFormat($event)"
          />
        </div>
      </v-col>
      <v-col v-if="allowDelete" cols="12" sm="6" md="6" class="pt-0 pb-0">
        <v-text-field
          :label="label"
          v-model="getValue"
          v-mask="mask"
          :rules="maskRules"
          @input="handleInput"
          append-outer-icon="mdi-close"
          @click:append-outer="deleteSelf"
        >
        </v-text-field>
      </v-col>
      <v-col v-else cols="12" sm="6" md="6" class="pt-0 pb-0">
        <v-text-field
          :label="label"
          v-model="getValue"
          v-mask="mask"
          :rules="maskRules"
          @input="handleInput"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="NumberType" class="pr-0 pl-0 mt-0">
      <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
        <div :id="$id('select_tel_select')">
          <v-select
            v-model="mask"
            :attach="attachStringMask"
            :items="[
              {
                text: '(###) ###-##-##',
                value: { mask: '(###) ###-##-##', tokens: this.hexTokens },
              },
              {
                text: '(###-##) #-##-##',
                value: { mask: '(###-##) #-##-##', tokens: this.hexTokens },
              },
              {
                text: 'Без маски',
                value: { mask: 'P################', tokens: this.hexTokens },
              },
            ]"
            :label="maskLabel"
            @change="setFormat($event)"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
        <v-text-field
          :label="label"
          v-model="getValue"
          v-mask="mask"
          :rules="maskRules"
          @input="handleInput"
        >
        </v-text-field>
      </v-col>
      <v-col v-if="allowDelete" cols="12" sm="4" md="4" class="pt-0 pb-0">
        <div :id="$id('select_tel_type')">
          <v-select
            v-model="NumberTypeValue"
            :attach="attachStringType"
            :items="[
              {
                text: 'Мобильный',
                value: 1,
              },
              {
                text: 'Офисный',
                value: 2,
              },
            ]"
            label="Тип Телефона"
            append-outer-icon="mdi-close"
            @click:append-outer="deleteSelf"
          />
        </div>
      </v-col>
      <v-col v-else cols="12" sm="4" md="4" class="pt-0 pb-0">
        <div :id="$id('select_tel_type')">
          <v-select
            v-model="NumberTypeValue"
            :attach="attachStringType"
            :items="[
              {
                text: 'Мобильный',
                value: 1,
              },
              {
                text: 'Офисный',
                value: 2,
              },
            ]"
            label="Тип Телефона"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  directives: {
    mask,
  },
  name: 'tel',
  data() {
    return {
      valid: false,
      resultsExample: {},
      hexTokens: {
        P: { pattern: /[+\d]/ },
        '#': { pattern: /\d/ },
      },
      attachStringMask: '#' + this.$id('select_tel_select') + ' .v-input',
      attachStringType: '#' + this.$id('select_tel_type') + ' .v-input',
      localValue: '',
      mask: null,
      NumberTypeLocal: 2,
      maskRules: [
        (v) => {
          let maxLength;
          switch (JSON.stringify(this.mask)) {
            case JSON.stringify({ mask: '(###) ###-##-##', tokens: this.hexTokens }):
              maxLength = 15;
              break;
            case JSON.stringify({ mask: '(###-##) #-##-##', tokens: this.hexTokens }):
              maxLength = 16;
              break;
            case JSON.stringify({ mask: 'P################', tokens: this.hexTokens }):
              maxLength = 1;
              break;
          }
          if (!v) return true;
          return (v && v.length >= maxLength) || 'Введите номер полностью';
        },
      ],
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maskLabel: {
      type: String,
      required: true,
    },
    reset: {
      type: Boolean,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    format: {
      type: Number,
      required: true,
    },
    NumberType: {
      type: Number,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
    NumberTypeValue: {
      get: function () {
        return this.NumberType;
      },
      set: function (value) {
        this.NumberTypeLocal = value;
        this.$emit('changeNumberType', this.NumberTypeLocal);
      },
    },
  },
  methods: {
    getFormat(formatId) {
      let format;
      let vm = this;
      switch (formatId) {
        case 1:
          format = { mask: '(###) ###-##-##', tokens: vm.hexTokens };
          break;
        case 2:
          format = { mask: '(###-##) #-##-##', tokens: vm.hexTokens };
          break;
        case 3:
          format = { mask: 'P################', tokens: vm.hexTokens };
          break;
        default:
          format = { mask: '(###) ###-##-##', tokens: vm.hexTokens };
      }
      return format;
    },
    setFormat(formatValue) {
      let formatId;
      switch (JSON.stringify(formatValue)) {
        case JSON.stringify({ mask: '(###) ###-##-##', tokens: this.hexTokens }):
          formatId = 1;
          break;
        case JSON.stringify({ mask: '(###-##) #-##-##', tokens: this.hexTokens }):
          formatId = 2;
          break;
        case JSON.stringify({ mask: 'P################', tokens: this.hexTokens }):
          formatId = 3;
          break;
      }
      this.$emit('changeFormat', formatId);
    },
    handleInput() {
      this.$emit('input', this.localValue);
    },
    deleteSelf() {
      this.$emit('deleteSelf', this.index);
    },
  },
  created() {
    let format;
    let vm = this;
    switch (this.format) {
      case 1:
        format = { mask: '(###) ###-##-##', tokens: vm.hexTokens };
        break;
      case 2:
        format = { mask: '(###-##) #-##-##', tokens: vm.hexTokens };
        break;
      case 3:
        format = { mask: 'P################', tokens: vm.hexTokens };
        break;
      default:
        format = { mask: '(###) ###-##-##', tokens: vm.hexTokens };
    }
    this.mask = format;
  },
  watch: {
    reset: function (val) {
      if (val === true) {
        this.mask = { mask: '(###) ###-##-##', tokens: this.hexTokens };
      }
    },
    resultsExample: function (val) {
      this.valid = !!val.isValid;
      this.$emit('input', this.localValue);
      if (!this.valid) {
        this.$emit('update:error', ['false']);
      } else {
        this.$emit('update:error', ['true']);
      }
    },
  },
};
</script>

<style scoped></style>
