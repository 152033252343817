(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? (module.exports = factory(require('vue2-datepicker')))
    : typeof define === 'function' && define.amd
    ? define(['vue2-datepicker'], factory)
    : ((global = global || self),
      ((global.DatePicker = global.DatePicker || {}),
      (global.DatePicker.lang = global.DatePicker.lang || {}),
      (global.DatePicker.lang.ru = factory(global.DatePicker))));
})(this, function (DatePicker) {
  'use strict';

  DatePicker =
    DatePicker && DatePicker.hasOwnProperty('default') ? DatePicker['default'] : DatePicker;

  function unwrapExports(x) {
    return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default')
      ? x['default']
      : x;
  }

  function createCommonjsModule(fn, module) {
    return (module = { exports: {} }), fn(module, module.exports), module.exports;
  }

  var ua = createCommonjsModule(function (module, exports) {
    Object.defineProperty(exports, '__esModule', {
      value: true,
    });
    exports['default'] = void 0;
    var locale = {
      months: [
        'січень',
        'лютий',
        'березень',
        'квітень',
        'травень',
        'червень',
        'липень',
        'серпень',
        'вересень',
        'жовтень',
        'листопад',
        'грудень',
      ],
      monthsShort: [
        'січень',
        'лютий',
        'березень',
        'квітень',
        'травень',
        'червень',
        'липень',
        'серпень',
        'вересень',
        'жовтень',
        'листопад',
        'грудень',
      ],
      weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', "п'ятниця", 'субота'],
      weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      firstDayOfWeek: 1,
      firstWeekContainsDate: 1,
    };
    var _default = locale;
    exports['default'] = _default;
    module.exports = exports.default;
  });

  var ua$1 = unwrapExports(ua);

  var lang = {
    formatLocale: ua$1,
    yearFormat: 'YYYY',
    monthFormat: 'MMM',
    monthBeforeYear: true,
  };
  DatePicker.locale('ua', lang);

  return lang;
});
