<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="$wait.is('where_to_buy')"
      loading-text="Загрузка... Пожалуйста подождите"
      hide-default-footer
      :page.sync="page"
      :items-per-page="-1"
      @page-count="pageCount = $event"
      @current-items="currentItemsInfo = $event"
      :search="search"
      item-key="Id"
      no-data-text="Ничего не найдено"
      no-results-text="Ничего не найдено"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="8">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Фильтр"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="2" class="ml-auto d-flex justify-center align-center">
            <v-dialog v-model="dialog" max-width="650px" eager>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" fab dark small color="primary" v-on="on" @click="openDialog">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <span class="headline">Новая запись</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="addWhereToBuy" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                          <v-text-field
                            label="*Название"
                            required
                            :rules="nameRules"
                            v-model="newWhereToBuy.Name"
                            ref="newWhereToBuyName"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <small>*обязательно к заполнению</small>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="dialog = false">Закрыть</v-btn>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="saveNewWhereToBuy" :disabled="!valid">
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-4" @click="openDialogEdit(item)"> mdi-pencil </v-icon>
        <v-icon small @click="openDialogDelete(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogEdit" max-width="650px" eager>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span class="headline">Изменить запись</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="editWhereToBuy" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" class="pt-0 pb-0">
                  <v-text-field
                    label="*Название модели"
                    required
                    :rules="nameRules"
                    v-model="newWhereToBuy.Name"
                    ref="editWhereToBuyName"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*обязательно к заполнению</small>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogEdit = false">Закрыть</v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="saveEditWhereToBuy(currentItemId)"
            :disabled="!valid"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">Вы уверены что хотите удалить запись?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Закрыть</v-btn>
          <v-btn color="blue darken-1" text @click="DeleteWhereToBuy(currentItemId)">Удалить</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="pt-12" v-if="items.length > 0">
      <div v-if="pageCount > 1" class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" />
      </div>
      <div class="text-center pt-2">
        <span v-if="items.length > currentItemsInfo.length">
          На странице: {{ currentItemsInfo.length }}
        </span>
        <br />
        <span>Всего: {{ items.length }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';

export default {
  data() {
    return {
      headers: [
        { value: 'Name', text: 'Название', sortable: true, class: 'black_text' },
        { text: 'Действия', value: 'actions', sortable: false },
        { value: 'Id', sortable: false, align: ' d-none' },
      ],
      newWhereToBuy: {
        Name: '',
      },
      currentItemId: 0,
      items: [],
      page: 1,
      pageCount: 0,
      currentItemsInfo: [],
      search: '',
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      valid: true,
      nameRules: [(v) => !!v || 'Название обязательное поле!'],
    };
  },
  computed: {},
  async beforeDestroy() {},
  async created() {
    await this.getWhereToBuy();
  },
  methods: {
    async refresh() {
      await this.getWhereToBuy();
    },
    async getWhereToBuy() {
      this.$wait.start('where_to_buy');
      await HTTP.get('where_to_buy').then(
        (response) => {
          this.items = response.data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
      this.$wait.end('where_to_buy');
    },
    openDialog() {
      if (this.$refs.addWhereToBuy) {
        this.newWhereToBuy.Name = '';
        this.$refs.addWhereToBuy.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.newWhereToBuyName.focus();
          });
        });
      });
    },
    openDialogEdit(item) {
      this.currentItemId = item.Id;
      this.dialogEdit = true;
      if (this.$refs.editWhereToBuy) {
        this.newWhereToBuy.Name = item.Name;
        this.$refs.editWhereToBuy.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.editWhereToBuyName.focus();
          });
        });
      });
    },
    openDialogDelete(item) {
      this.currentItemId = item.Id;
      this.dialogDelete = true;
    },
    async saveNewWhereToBuy() {
      if (this.$refs.addWhereToBuy.validate()) {
        await HTTP.post('new_where_to_buy', this.newWhereToBuy).then(
          () => {
            this.dialog = false;
            this.refresh();
            this.$toastr.s('Запись добавлена');
          },
          (error) => {
            if (error.response.data.Error) {
              this.$toastr.e(error.response.data.Error);
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    async saveEditWhereToBuy(id) {
      if (this.$refs.editWhereToBuy.validate()) {
        await HTTP.post('/where_to_buy/' + id, this.newWhereToBuy).then(
          () => {
            this.dialogEdit = false;
            this.refresh();
            this.$toastr.s('Запись изменена');
          },
          (error) => {
            if (error.response.data.Error) {
              this.$toastr.e(error.response.data.Error);
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    async DeleteWhereToBuy(id) {
      await HTTP.post('/where_to_buy/delete/' + id).then(
        () => {
          this.dialogDelete = false;
          this.refresh();
          this.$toastr.s('Запись удалена');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped>
.link {
  color: #1976d2;
  cursor: pointer;
  background-color: transparent;
}

.invisible {
  display: none;
}

.v-data-table >>> .black_text {
  color: black !important;
}

.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
