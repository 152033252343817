<template>
  <div class="mb-2">
    <div class="label">
      <span
        ><b>{{ label }}:</b></span
      >
      <span class="mdi mdi-square-edit-outline input-toggler" @click="inputBoxShow" />
    </div>
    <div v-if="!inputShow" class="item">
      <div v-if="!loader">
        <span v-if="getValue !== '' && getValue !== null">
          <span v-sanitize="getValue"></span>
        </span>
        <span v-else>[Не задано]</span>
      </div>
      <div v-else>
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
    <div v-if="inputShow" ref="ckeditor_with_button">
      <v-card>
        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12" class="pa-0">
              <utils-ckeditor v-model="getValue"></utils-ckeditor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="escape">Закрыть</v-btn>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="call_Callback"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ckeditorWithButton',
  data() {
    return {
      localValue: '',
      inputShow: false,
    };
  },
  props: {
    value: {
      type: String,
      //required: true,
    },
    label: {
      type: String,
      required: false,
    },
    openedInputVar: {
      type: Boolean,
      required: true,
    },
    loader: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    getValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.localValue = value;
      },
    },
  },
  methods: {
    inputBoxShow() {
      if (!this.openedInputVar) {
        this.escapeValue = this.value;
        this.inputShow = true;
        this.localValue = this.value;
        this.$emit('inputBoxSwitch');
      } else {
        this.$toastr.e('Есть открытые и не заполненнные элементы');
      }
    },
    call_Callback() {
      this.inputShow = false;
      if (this.localValue === this.value) {
        this.$emit('inputBoxSwitch');
        return; // Если значение не поменялось - ничего не делаем
      }
      this.$emit('inputBoxSwitch');
      // this.$emit('input', this.localValue);
      this.$emit('callback', this.localValue);
    },
    escape() {
      this.localValue = this.escapeValue;
      this.inputShow = false;
      this.$emit('inputBoxSwitch');
    },
  },
};
</script>

<style lang="css">
.h1 {
  font-size: 2.1875rem;
}

.input-toggler {
  cursor: pointer;
  padding-left: 8px;
}
.label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.input-with-button .v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.input-with-button .v-text-field--single-line input {
  padding-top: 0 !important;
  margin-bottom: 3px;
  line-height: 1 !important;
}

.item {
  display: inline-block;
  position: relative;
  width: 100%;
}
</style>
