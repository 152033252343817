var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.NumberType)?_c('v-row',{staticClass:"pr-0 pl-0 mt-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',{attrs:{"id":_vm.$id('select_tel_select')}},[_c('v-select',{attrs:{"attach":_vm.attachStringMask,"items":[
            {
              text: '(###) ###-##-##',
              value: { mask: '(###) ###-##-##', tokens: this.hexTokens },
            },
            {
              text: '(###-##) #-##-##',
              value: { mask: '(###-##) #-##-##', tokens: this.hexTokens },
            },
            {
              text: 'Без маски',
              value: { mask: 'P################', tokens: this.hexTokens },
            } ],"label":_vm.maskLabel},on:{"change":function($event){return _vm.setFormat($event)}},model:{value:(_vm.mask),callback:function ($$v) {_vm.mask=$$v},expression:"mask"}})],1)]),(_vm.allowDelete)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":_vm.label,"rules":_vm.maskRules,"append-outer-icon":"mdi-close"},on:{"input":_vm.handleInput,"click:append-outer":_vm.deleteSelf},model:{value:(_vm.getValue),callback:function ($$v) {_vm.getValue=$$v},expression:"getValue"}})],1):_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":_vm.label,"rules":_vm.maskRules},on:{"input":_vm.handleInput},model:{value:(_vm.getValue),callback:function ($$v) {_vm.getValue=$$v},expression:"getValue"}})],1)],1):_vm._e(),(_vm.NumberType)?_c('v-row',{staticClass:"pr-0 pl-0 mt-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{attrs:{"id":_vm.$id('select_tel_select')}},[_c('v-select',{attrs:{"attach":_vm.attachStringMask,"items":[
            {
              text: '(###) ###-##-##',
              value: { mask: '(###) ###-##-##', tokens: this.hexTokens },
            },
            {
              text: '(###-##) #-##-##',
              value: { mask: '(###-##) #-##-##', tokens: this.hexTokens },
            },
            {
              text: 'Без маски',
              value: { mask: 'P################', tokens: this.hexTokens },
            } ],"label":_vm.maskLabel},on:{"change":function($event){return _vm.setFormat($event)}},model:{value:(_vm.mask),callback:function ($$v) {_vm.mask=$$v},expression:"mask"}})],1)]),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":_vm.label,"rules":_vm.maskRules},on:{"input":_vm.handleInput},model:{value:(_vm.getValue),callback:function ($$v) {_vm.getValue=$$v},expression:"getValue"}})],1),(_vm.allowDelete)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{attrs:{"id":_vm.$id('select_tel_type')}},[_c('v-select',{attrs:{"attach":_vm.attachStringType,"items":[
            {
              text: 'Мобильный',
              value: 1,
            },
            {
              text: 'Офисный',
              value: 2,
            } ],"label":"Тип Телефона","append-outer-icon":"mdi-close"},on:{"click:append-outer":_vm.deleteSelf},model:{value:(_vm.NumberTypeValue),callback:function ($$v) {_vm.NumberTypeValue=$$v},expression:"NumberTypeValue"}})],1)]):_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{attrs:{"id":_vm.$id('select_tel_type')}},[_c('v-select',{attrs:{"attach":_vm.attachStringType,"items":[
            {
              text: 'Мобильный',
              value: 1,
            },
            {
              text: 'Офисный',
              value: 2,
            } ],"label":"Тип Телефона"},model:{value:(_vm.NumberTypeValue),callback:function ($$v) {_vm.NumberTypeValue=$$v},expression:"NumberTypeValue"}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }