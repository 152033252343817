<template>
  <div class="fill-height d-flex">
    <v-card height="100%" width="200">
      <v-navigation-drawer permanent style="background: #bcc1c8">
        <v-list dense nav>
          <template v-for="item in items">
            <v-list-item :to="item.path" :key="item.title" v-if="!item.subLinks">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else :key="item.title">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(subLink, i) in item.subLinks"
                :key="i"
                :to="subLink.path"
                class="pl-5"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subLink.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      items: [
        { title: 'Email', path: '/admin/settings/email' },
        { title: 'Сайт', path: '/admin/settings/site' },
      ],
    };
  },
  computed: {},
  methods: {
    breadcrumbs() {
      this.$store.dispatch('app/SET_BREADCRUMBS', [
        {
          text: 'Главная',
          to: '/admin',
          disabled: false,
        },
        {
          text: 'Настройки',
          to: '/admin/settings',
          disabled: true,
        },
      ]);
    },
  },
  created() {
    this.breadcrumbs(); // дублирование нужно!
  },
  beforeUpdate() {
    this.breadcrumbs(); // дублирование нужно!
  },
};
</script>
<style lang="css" scoped>
.v-navigation-drawer .v-list {
  background: unset;
}
</style>
