const getters = {
  userPermissions: (state) => JSON.parse(state.permissions) || [],
  checkPermission: (state, getters) => (roleCode) => {
    const result = !!(
      roleCode === undefined || getters.userPermissions.filter((el) => el === roleCode).length > 0
    );
    if (getters.userPermissions.includes('FULL-PERMISSIONS')) {
      return true;
    }
    return result;
  },
  userInfo: (state) => {
    return (
      JSON.parse(state.user) || {
        active: null,
        email: null,
        name: null,
        id: null,
      }
    );
  },
  networkError: (state) => state.networkError,
};

export default getters;
