<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="$wait.is('sku')"
      loading-text="Загрузка... Пожалуйста подождите"
      hide-default-footer
      :page.sync="page"
      :items-per-page="-1"
      @page-count="pageCount = $event"
      @current-items="currentItemsInfo = $event"
      :search="search"
      item-key="Id"
      no-data-text="Ничего не найдено"
      no-results-text="Ничего не найдено"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="8">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Фильтр"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="2" class="ml-auto d-flex justify-center align-center">
            <v-btn class="mx-2" fab dark small color="primary" @click="handleFileImport">
              <v-icon dark>mdi-paperclip</v-icon>
            </v-btn>

            <input
              @change="handleFileUpload($event)"
              accept=".xlsx"
              ref="FileInput"
              type="file"
              class="d-none"
            />

            <v-dialog v-model="dialog" max-width="650px" eager>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" fab dark small color="primary" v-on="on" @click="openDialog">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <span class="headline">Новый Артикул</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="addSku" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="6" class="pt-0 pb-0">
                          <v-text-field
                            label="*Артикул"
                            required
                            :rules="artikulRules"
                            v-model="newSku.Artikul"
                            ref="newSkuArtikul"
                            @input.native="artikulInputMask"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-0">
                          <v-text-field
                            label="*Название модели"
                            required
                            :rules="modelRules"
                            v-model="newSku.Name"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <small>*обязательно к заполнению</small>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="dialog = false">Закрыть</v-btn>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="saveNewSku" :disabled="!valid">
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-4" @click="openDialogEdit(item)"> mdi-pencil </v-icon>
        <v-icon small @click="openDialogDelete(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogEdit" max-width="650px" eager>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span class="headline">Изменить Артикул</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="editSku" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    label="*Артикул"
                    required
                    :rules="artikulRules"
                    v-model="newSku.Artikul"
                    ref="editSkuArtikul"
                    @input.native="artikulInputMask"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    label="*Название модели"
                    required
                    :rules="modelRules"
                    v-model="newSku.Name"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*обязательно к заполнению</small>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogEdit = false">Закрыть</v-btn>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="saveEditSku(currentItemId)" :disabled="!valid">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">Вы уверены что хотите удалить артикул?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Закрыть</v-btn>
          <v-btn color="blue darken-1" text @click="DeleteSku(currentItemId)">Удалить</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="pt-12" v-if="items.length > 0">
      <div v-if="pageCount > 1" class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" />
      </div>
      <div class="text-center pt-2">
        <span v-if="items.length > currentItemsInfo.length">
          На странице: {{ currentItemsInfo.length }}
        </span>
        <br />
        <span>Всего: {{ items.length }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';
import * as XLSX from 'xlsx';

export default {
  data() {
    return {
      headers: [
        {
          value: 'Artikul',
          text: 'Артикул',
          sortable: true,
          filterable: true,
          class: 'black_text',
        },
        { value: 'Name', text: 'Название модели', sortable: true, class: 'black_text' },
        { text: 'Действия', value: 'actions', sortable: false },
        { value: 'Id', sortable: false, align: ' d-none' },
      ],
      newSku: {
        Artikul: '',
        Name: '',
      },
      currentItemId: 0,
      items: [],
      page: 1,
      pageCount: 0,
      currentItemsInfo: [],
      search: '',
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      valid: true,
      File: '',
      artikulRules: [
        (v) => {
          if (!v) {
            return 'Артикул обязательное поле!';
          } else if (v.length < 7) {
            return 'Артикул не может быть меньше 7 символов';
          }
          return true;
        },
      ],
      modelRules: [(v) => !!v || 'Модель обязательное поле!'],
    };
  },
  computed: {},
  async beforeDestroy() {},
  async created() {
    this.getSku();
  },
  methods: {
    handleFileImport() {
      this.$refs.FileInput.click();
    },
    async handleFileUpload(event) {
      this.File = event;
      let Array;
      if (event) {
        Array = await this.readFile();
        let BackendArray = [];
        Array.forEach((element) => {
          if (element.Артикул && element.Назва) {
            if (typeof element.Артикул === 'number') {
              element.Артикул = element.Артикул.toString(10);
            }
            BackendArray.push({ Artikul: element.Артикул, Name: element.Назва });
          }
        });
        if (BackendArray.length > 0) {
          await HTTP.post('sku/update_new_sku', BackendArray).then(
            () => {
              this.refresh();
              this.$toastr.s('Новые артикулы загружены');
            },
            (error) => {
              if (error.response.data.Error) {
                if (error.response.data.Error.includes('Error 1062: Duplicate entry')) {
                  this.$toastr.e('В файле дублируются артикулы!');
                } else {
                  this.$toastr.e(error.response.data.Error);
                }
              } else {
                this.$toastr.e(error);
              }
            }
          );
        } else {
          this.$toastr.e('Неправильный файл данных');
        }
      }
    },
    readFile() {
      return new Promise((resolve, reject) => {
        let vm = this;
        let reader = new FileReader();
        reader.onload = async function (e) {
          let Workbook = XLSX.read(e.target.result, { type: 'array', cellDates: true });
          let FileArray = XLSX.utils.sheet_to_json(Workbook.Sheets[Workbook.SheetNames[0]], {
            range: 1,
          });
          if (!e.target.result) {
            reject();
            return;
          }
          vm.$refs.FileInput.reset();
          vm.$refs.FileInput.clearableCallback();
          resolve(FileArray);
        };
        reader.readAsArrayBuffer(this.File);
      });
    },
    artikulInputMask() {
      this.newSku.Artikul = this.newSku.Artikul.replace(/[^0-9|-]/g, '');
      if (this.newSku.Artikul.length > 10) {
        this.newSku.Artikul = this.newSku.Artikul.substring(0, 10);
      }
    },
    refresh() {
      this.getSku();
    },
    getSku() {
      //this.$wait.start('sku');
      HTTP.get('sku').then(
        (response) => {
          this.items = response.data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
      // this.$wait.end('sku');
    },
    openDialog() {
      if (this.$refs.addSku) {
        this.newSku.Artikul = '';
        this.newSku.Name = '';
        this.$refs.addSku.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.newSkuArtikul.focus();
          });
        });
      });
    },
    openDialogEdit(item) {
      this.currentItemId = item.Id;
      this.dialogEdit = true;
      if (this.$refs.editSku) {
        this.newSku.Artikul = item.Artikul;
        this.newSku.Name = item.Name;
        this.$refs.editSku.resetValidation();
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.editSkuArtikul.focus();
          });
        });
      });
    },
    openDialogDelete(item) {
      this.currentItemId = item.Id;
      this.dialogDelete = true;
    },
    async saveNewSku() {
      if (this.$refs.addSku.validate()) {
        await HTTP.post('new_sku', this.newSku).then(
          () => {
            this.dialog = false;
            this.refresh();
            this.$toastr.s('Артикул добавлен');
          },
          (error) => {
            if (error.response.data.Error) {
              if (error.response.data.Error.includes('Error 1062: Duplicate entry')) {
                this.$toastr.e('Такой номер артикула уже существует');
              } else {
                this.$toastr.e(error.response.data.Error);
              }
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    async saveEditSku(id) {
      if (this.$refs.editSku.validate()) {
        await HTTP.post('/sku/' + id, this.newSku).then(
          () => {
            this.dialogEdit = false;
            this.refresh();
            this.$toastr.s('Артикул изменен');
          },
          (error) => {
            if (error.response.data.Error) {
              if (error.response.data.Error.includes('Error 1062: Duplicate entry')) {
                this.$toastr.e('Такой номер артикула уже существует');
              } else {
                this.$toastr.e(error.response.data.Error);
              }
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    async DeleteSku(id) {
      await HTTP.post('/sku/delete/' + id).then(
        () => {
          this.dialogDelete = false;
          this.refresh();
          this.$toastr.s('Артикул удален');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped>
.link {
  color: #1976d2;
  cursor: pointer;
  background-color: transparent;
}

.invisible {
  display: none;
}

.v-data-table >>> .black_text {
  color: black !important;
}

.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
