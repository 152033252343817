<template>
  <div>
    <core-toolbar />
    <core-drawer
      :background="require(`@/assets/images/sidebar.jpg`)"
      title="Einhell Service"
      :logo="require(`@/assets/small_logo.jpg`)"
      :items="sidebarItems"
    />
    <core-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  data: () => ({
    sidebarItems: [
      {
        icon: 'mdi-file-alert',
        text: 'Гарантии',
        route: '/admin/warranty-requests',
      },
      {
        icon: 'mdi-book-open-variant',
        text: 'Справочники',
        route: '/admin/references',
      },
      {
        icon: 'mdi-file-multiple',
        text: 'Хранилище файлов',
        route: '/admin/storage',
      },
      {
        icon: 'mdi-account',
        text: 'Сотрудники',
        route: '/admin/users',
      },
      {
        icon: 'mdi-settings',
        text: 'Настройки',
        route: '/admin/settings',
        class: 'settings', // должен быть последним, настройки стилей что б вниз
      },
    ],
  }),
  methods: {},
};
</script>
