<template>
  <div>
    <utils-ckeditor v-model="dataPrivacy" />
    <v-btn class="mt-3" @click="setDataPrivacy">Сохранить</v-btn>
  </div>
</template>
<script>
import { HTTP } from '@/utils/plugins/axios/axios';

export default {
  data() {
    return {
      dataPrivacy: '',
    };
  },
  computed: {},
  async created() {
    await this.getDataPrivacy();
  },
  methods: {
    async refresh() {
      await this.getDataPrivacy();
    },
    async getDataPrivacy() {
      await HTTP.get('data_privacy').then(
        (response) => {
          this.dataPrivacy = response.data.Data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    async setDataPrivacy() {
      await HTTP.post('data_privacy', { data: this.dataPrivacy }).then(
        () => {
          this.refresh();
          this.$toastr.s('Изменено успешно');
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
  },
};
</script>

<style lang="css" scoped></style>
